import { FC } from "react";

import { styled } from "providers/theme";

const C: FC<{ className?: string; formId?: string }> = ({ className, formId }) => (
  <button className={className} type="submit">
    Submit {formId}
  </button>
);

export const HiddenSubmit = styled(C)`
  border: none;
  height: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 0;
`;
