import { css } from "providers/theme";

import type { AuthLayoutProps } from "./AuthLayout";

export const style = css<AuthLayoutProps>`
  min-height: 100vh;
  display: flex;
  width: 100vw;

  aside {
    flex: 1;
    background-color: ${({ theme }) => theme.palette.light.dark};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    color: ${({ theme }) => theme.palette.gray.dark};

    img {
      max-width: 75%;
      margin-bottom: 2rem;
    }

    h1 {
      font-size: 2rem;
      text-align: center;
      font-weight: 300;
    }

    small {
      max-width: 15rem;
      text-align: center;
      margin-bottom: 2rem;
    }
  }

  main {
    width: 33.33%;
    min-width: 20rem;
    min-height: 100vh;
    background-color: ${({ theme }) => theme.palette.light.main};
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: auto;
    padding: 4rem 0;

    & > a {
      position: absolute;
      top: 2rem;

      left: 2rem;

      & > img {
        width: 2rem;
      }
    }

    & > span[role="code"] {
      display: block;
      position: absolute;
      bottom: 2rem;
      left: 2rem;
      font-size: 0.75rem;
    }

    & > div {
      display: flex;
      flex-direction: column;
      width: 60%;
      max-width: 20rem;
      margin: 3rem auto;
    }
  }
`;
