import { FC } from "react";
import { useMutation } from "react-relay/hooks";

import { AddressFieldset } from "components/Form2";
import { appNavigation, t } from "helpers";
import { DetailPageLayout } from "layouts/DetailPageLayout";
import { userMutation, userQuery } from "providers/Api";
import type { userQuery as userQueryType } from "providers/Api/__generated__/userQuery.graphql";

import { useSchema, ProfileFormData } from "./schema";
import { PersonalDetails } from "./sections/PersonalDetails";
import { WorkData } from "./sections/WorkData";

interface Props {
  user: NonNullable<userQueryType["response"]["user"]>;
}

export const UserDetailPage: FC<Props> = () => {
  const [dispatch] = useMutation(userMutation);
  const schema = useSchema();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSubmit = (data: ProfileFormData, { entity }: any) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const profilePhoto = data.profile.profilePhoto;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const input = JSON.parse(JSON.stringify({ userUuid: entity.id, ...data })) as any;

    // @ts-ignore TODO: ad middle-name support
    delete input.profile.age;
    delete input.profile.profilePhoto;

    input.profile.birthday = input.profile.birthday
      ? new Date(input.profile.birthday).toISOString().substr(0, 10)
      : null;

    input.profile.nameday = input.profile.nameday
      ? new Date(input.profile.nameday).toISOString().substr(0, 10)
      : null;

    const uploadables = profilePhoto ? { profile: { profilePhoto } } : undefined;

    // @ts-ignore TODO: fix
    dispatch({ uploadables, variables: { user: input } });
  };

  const sections = [
    { component: PersonalDetails, key: "profile", label: t("Personal Info") },
    { component: AddressFieldset, key: "address", label: t("Address") },
    { component: WorkData, key: "workData", label: t("Work data") },
  ];

  return (
    <DetailPageLayout<userQueryType>
      listRoute={appNavigation.userList()}
      path="user"
      query={userQuery}
      schema={schema}
      sections={sections}
      title={(e) => {
        return e?.profile?.fullName;
      }}
      onSave={handleSubmit}
    />
  );
};
