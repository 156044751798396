import graphql from "babel-plugin-relay/macro";

export const contactsPaginationQuery = graphql`
  query contactsPaginationQuery($first: Int, $after: String, $search: String) {
    ...contacts_pagination @arguments(search: $search, first: $first, after: $after)
  }
`;

export const contactsPaginationFragment = graphql`
  fragment contacts_pagination on Query
  @argumentDefinitions(
    search: { type: "String" }
    first: { type: "Int", defaultValue: 10 }
    after: { type: "String" }
  ) {
    list: contactPersons(first: $first, after: $after, filter: { search: $search })
      @connection(key: "ContactPerson_list") {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          position
          phone
          mail
        }
      }
    }
  }
`;
