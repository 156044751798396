import { FC, ComponentType } from "react";

import { useField } from "./useField";

export interface FieldProps extends Record<string, unknown> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: ComponentType<any>;
  // ComponentType<FieldInterface<any> & Record<string, any>>;
  name: string;
}

export const Field: FC<FieldProps> = ({ component, helperText, name, ...props }) => {
  const { error, helperText: errorText, ...field } = useField(name);
  const C = component;

  return <C {...field} {...props} error={error} helperText={errorText || helperText} />;
};
