import { FC } from "react";

import { Container, Col, Row, Field } from "components";
import { TextInput } from "components/inputs/TextInput";
import { ListPicker } from "containers/ListPicker";
import { t } from "helpers";
import { userPaginationFragment, userPaginationQuery } from "providers";

export const BasicInfoSection: FC = () => {
  return (
    <Container>
      <Row>
        <Col className="mb-4" md={6} sm={6} xs={12}>
          <Field component={TextInput} label={t("Name")} name="info.name" />
          <Field component={TextInput} label={t("ICO")} name="info.ico" />
          <Field component={TextInput} label={t("TaxId")} name="info.taxId" />
          <Field component={TextInput} label={t("Vat number")} name="info.vat" />
        </Col>

        <Col className="mb-4" md={6} sm={6} xs={12}>
          <Field component={TextInput} label={t("Type")} name="info.type" />

          <Field component={TextInput} label={t("Status")} name="info.status" />

          <Field
            multiple
            component={ListPicker}
            fragment={userPaginationFragment}
            label={t("Responsible person")}
            name="info.status"
            query={userPaginationQuery}
          />
        </Col>

        <Col className="mb-4" md={12} sm={12} xs={12}>
          <Field multiline component={TextInput} label={t("Note")} name="info.note" rows={3} />
        </Col>
      </Row>
    </Container>
  );
};
