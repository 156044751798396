import {
  ReactNode,
  forwardRef,
  MouseEvent,
  useCallback,
  CSSProperties,
  SyntheticEvent,
} from "react";

import { Icon } from "components/common/Icon";
import type { IconProps } from "components/common/Icon";
import { styled } from "providers/theme";
import type { AppTheme } from "providers/theme";

import { styles } from "./Button.styled";
import { createRipple } from "./createRipple";

export interface ButtonProps {
  children?: ReactNode;
  className?: string;
  color?: keyof AppTheme["palette"];
  disabled?: boolean;
  endAdornment?: ReactNode;
  endIcon?: IconProps["name"];
  /** Form ID to submit - use when you cannot put button inside <form> tag */
  form?: string;
  fullWidth?: boolean;
  onClick?: (e: SyntheticEvent) => void;
  // TODO: inspect how to type REF properly
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref?: any;
  /** Round corners */
  rounded?: boolean;
  size?: "small" | "normal" | "large";
  startAdornment?: ReactNode;
  startIcon?: IconProps["name"];
  style?: CSSProperties;
  title?: string;
  type?: /**	The button is a clickable button */
  | "button"
    /** The button is a submit button (submits form-data) */
    | "submit"
    /** The button is a reset button (resets the form-data to its initial values) */
    | "reset";
  variant?: "contained" | "outlined" | "text" | "raised";
}

const Component = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      className,
      disabled,
      endAdornment,
      endIcon,
      form,
      onClick,
      startAdornment,
      startIcon,
      style,
      title,
      type,
      variant,
    },
    ref
  ) => {
    const handleClick = useCallback(
      (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        variant !== "outlined" && createRipple(e); // ignore ripple for transparent bg
        onClick && onClick(e);
      },
      [onClick, variant]
    );
    return (
      <button
        ref={ref}
        className={className}
        disabled={disabled}
        form={form}
        style={style}
        title={title}
        type={type}
        onClick={onClick && handleClick}
      >
        {startIcon && <Icon className="mr-2" name={startIcon} />}
        {startAdornment && <span className="start mr-2">{startAdornment}</span>}
        {children}
        {endAdornment && <span className="start ml-2">{endAdornment}</span>}
        {endIcon && <Icon className="ml-2" name={endIcon} />}
      </button>
    );
  }
);

export const Button = styled(Component).attrs((p) => ({
  ...p,
  size: p.size || "normal",
  variant: p.variant || "text",
}))<ButtonProps>`
  ${styles}
`;
