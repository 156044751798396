import { FC, useCallback, ComponentType } from "react";
import {
  GraphQLTaggedNode,
  MappedFragmentProps,
  RelayPaginationProp,
  createPaginationContainer,
} from "react-relay";

import { DataTable } from "components/DataTable";
import { useForceReRender } from "helpers/useForceRender";

interface Props {
  columns: Record<string, unknown>[];
  listData: {
    list: {
      edges: { cursor: string; node: Record<string, unknown> }[];
      pageInfo: { endCursor?: string; startCursor?: string };
      totalCount: number;
    };
  };
  relay: RelayPaginationProp;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ListTable: FC<Props> = (props) => {
  const { columns, listData, relay } = props;
  const { list } = listData || {};
  const data = (list?.edges || []).map((e) => e.node);

  const forceReRender = useForceReRender();
  const loadMore = relay.loadMore;

  const onLoadMore = useCallback(
    (next: number) => {
      return new Promise<void>((res) => {
        loadMore(next, () => {
          forceReRender();
          res();
        });
      });
    },
    [forceReRender, loadMore]
  );

  return (
    <DataTable
      // @ts-ignore TODO: fix typing
      columns={columns}
      data={data}
      isLoading={relay.isLoading()}
      loadMore={onLoadMore}
      total={list?.totalCount || 0}
    />
  );
};

export const createPaginatedTable = (
  query: GraphQLTaggedNode,
  fragment: GraphQLTaggedNode
  // TODO: fix typing
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): ComponentType<MappedFragmentProps<any>> =>
  // @ts-ignore TODO: fix typing
  createPaginationContainer(
    // @ts-ignore TODO: fix once
    ListTable,
    {
      listData: fragment,
    },
    {
      direction: "forward",
      getVariables(prev, next) {
        // @ts-ignore TODO: ppp
        const after = prev.listData?.list.pageInfo.endCursor;

        return {
          ...prev,
          after: after,
          first: next.count,
        };
      },
      query,
    }
  );
