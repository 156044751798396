import type { SessionState } from "./AuthProvider";

const PERSIST_KEY = "AuthResponse";

export interface PersistedState {
  wasLoggedIn: boolean;
}

export function getPersistedState(): undefined | PersistedState {
  const val = localStorage.getItem(PERSIST_KEY);
  try {
    const session: PersistedState | undefined = val ? JSON.parse(val) : undefined;

    if (!session) {
      return undefined;
    }

    return session;
  } catch {
    return undefined;
  }
}

export function setPersistedState(val: SessionState): void {
  try {
    if (val && val.user && val.user.id) {
      localStorage.setItem(PERSIST_KEY, JSON.stringify({ wasLoggedIn: true }));
    } else {
      localStorage.removeItem(PERSIST_KEY);
    }
  } catch {}
}
