import { css } from "providers/theme";

import type { AppLayoutProps } from "./AppLayout";

export const style = css<AppLayoutProps>`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;

  & > div[role="grid"] {
    flex-direction: row;
    display: flex;
    height: 100%;
    width: 100%;

    aside {
      background-color: ${({ theme }) => theme.palette.dark.main};
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      padding: 1rem;
      min-width: 16rem;
      display: flex;
      max-width: 18rem;
      color: ${({ theme }) => theme.palette.light.main};

      .brand {
        margin: 1rem auto 2rem 0.5rem;
        align-items: center;
        display: flex;
        font-weight: bold;

        & > div {
          color: ${({ theme }) => theme.palette.gray.light};
          display: flex;
          flex-direction: column;
          margin-left: 0.75rem;
          font-size: 1.25rem;
          line-height: 1;

          & > span {
            margin-bottom: 0.25rem;
          }

          & > small {
            font-size: 0.75rem;
            text-transform: lowercase;
            font-weight: 300;
            color: ${({ theme }) => {
              return theme.palette.gray.dark;
            }};
          }
        }
      }

      a,
      button {
        cursor: pointer;
        color: ${({ theme }) => theme.palette.light.main};

        &:hover,
        &.active {
          color: ${({ theme }) => theme.palette.primary.main};
        }
      }

      border-right: 1px solid ${({ theme }) => theme.palette.light.dark};
    }

    main {
      flex: 1;
      background: ${({ theme }) => theme.palette.light.main};
      overflow: auto;
    }
  }
`;
