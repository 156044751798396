import { ReactElement, ReactNode } from "react";
import { TableInstance } from "react-table";

import { cn } from "helpers";

import { Th, Tr, Table, Thead, TBody } from ".";

interface RenderTableBaseProps<T extends Record<string, unknown>> {
  children?: ReactNode;
  loadMore?: (next: number) => Promise<void>;
  table: TableInstance<T>;
}

export function RenderTableBase<T extends Record<string, unknown>>({
  children,
  table,
}: RenderTableBaseProps<T>): ReactElement {
  const { getTableBodyProps, getTableProps, headerGroups } = table;

  return (
    <Table {...getTableProps()}>
      <Thead>
        {headerGroups.map((headerGroup) => (
          <Tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, i) => (
              <Th
                // @ts-ignore TODO: inspect problem
                className={cn({ expand: column.expand })}
                // @ts-ignore TODO: fix typings
                column={column}
                key={i}
                onResize={() => {
                  table.dispatch({ type: "reload" });
                }}
              />
            ))}
          </Tr>
        ))}
      </Thead>

      <TBody {...getTableBodyProps()}>{children}</TBody>
    </Table>
  );
}
