import { TextField } from "@material-ui/core";
import { FC } from "react";

import { Icon } from "components/Icon";

interface Props {
  className?: string;
  onChange: (val: null | string) => void;
  value: null | string;
}

export const SearchBar: FC<Props> = ({ onChange, value }) => {
  return (
    <div className="py-2">
      <TextField
        fullWidth
        InputProps={{ startAdornment: <Icon name="search" /> }}
        size="small"
        value={value || ""}
        variant="outlined"
        onChange={(e) => onChange(e.currentTarget.value || "")}
      />
    </div>
  );
};
