import { ApolloClient, InMemoryCache, Operation } from "@apollo/client";

import { appEndpoint, host, publicEndpoint } from "providers/ApiProvider";

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  credentials: "include",
  defaultOptions: {
    query: {
      errorPolicy: "all",
      fetchPolicy: "network-only",
    },
  },
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
  },
  queryDeduplication: false,
  // method: "POST",
  // uri: "https://48p1r2roz4.sse.codesandbox.io",
  uri: ({ operationName }: Operation) => {
    const isPublic = operationName.startsWith("public");

    const url = `${host + (isPublic ? publicEndpoint : appEndpoint)}`;
    return url;
  },
});
