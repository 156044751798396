import { FC, ReactNode } from "react";

import { cn } from "helpers";
import { styled } from "providers/theme";

export interface UIFieldProps {
  className?: string;
  error?: boolean;
  helperText: ReactNode;
  id?: string;
  label?: ReactNode;
}

const C: FC<UIFieldProps> = ({ children, className, error, helperText, id, label }) => {
  return (
    <div className={cn(className, { "has-error": !!error })}>
      {label && <label htmlFor={id}>{label}</label>}

      {children}

      {helperText && <span className="helper-text">{helperText}</span>}
    </div>
  );
};

export const UIField = styled(C)<UIFieldProps>`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  margin-bottom: 0.5rem;

  .helper-text {
    font-size: 0.75rem;
    font-style: italic;
    color: ${({ theme }) => theme.palette.gray.dark};
    margin: 0.25rem 0 0.5rem 0;
  }

  label {
    margin-bottom: 0.25rem;
    font-size: 0.75rem;
  }

  & > input,
  & > select {
    padding: 0.25rem 0.5rem;
    border: none;
    border-bottom: 1px solid ${({ theme }) => theme.textColor};
    line-height: 1rem;
    font-size: 0.875rem;
    min-height: 2rem;
    opacity: 1;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    flex-direction: row;
    border-radius: ${({ theme }) => theme.radius.tiny};

    &[disabled] {
      color: ${({ theme }) => theme.palette.gray.dark};
      background-color: ${({ theme }) => theme.palette.gray.light};
    }
  }

  &.has-error {
    color: ${({ theme }) => theme.palette.danger.main};
    .helper-text {
      color: ${({ theme }) => theme.palette.danger.main};
    }

    input,
    select {
      border-bottom-color: ${({ theme }) => theme.palette.danger.main};
    }
  }
`;
