import Alert from "@material-ui/lab/Alert";
import { FC } from "react";
import { Link } from "react-router-dom";

import { Icon } from "components";
import { t } from "helpers";
import { appNavigation } from "helpers";
import { AuthLayout } from "layouts";

export const SignUpPage: FC = () => {
  return (
    <AuthLayout>
      <h2 className="mb-5">{t("Want new account?")}</h2>

      <Alert className="mb-3" severity="warning">
        {t("New accounts are disabled temporarly, for more details, feel free to ")}
        {/* TODO: add sales action */}
        <Link to="#contact">{t("Contact our sales")}</Link>.
      </Alert>

      <Link to={appNavigation.login()}>
        <Icon name="arrow-left" /> {t("Back to login")}
      </Link>
    </AuthLayout>
  );
};
