import { FC, useCallback, useMemo, useState } from "react";
import { GraphQLTaggedNode } from "react-relay";

import { Container, Row } from "components";
import { SearchBar } from "components/SearchBar";
import { QueryRenderer } from "providers";

import { createPaginatedTable } from "./createPaginatedTable";

interface Props {
  // Pagination query
  columns: unknown[];
  fragment: GraphQLTaggedNode;
  query: GraphQLTaggedNode;
}

interface Filters {
  [key: string]: unknown;
  search: null | string; // TODO
}

export const ListingPageLayout: FC<Props> = ({ columns, fragment, query }) => {
  const [filters, setFilters] = useState<Filters>({ search: null });

  const handleSearch = useCallback((v) => setFilters((s) => ({ ...s, search: v })), []);

  const ListingTable = useMemo(() => createPaginatedTable(query, fragment), [query, fragment]);

  return (
    <Container>
      <Row>
        <SearchBar value={filters.search} onChange={handleSearch} />
      </Row>
      <Row>
        <QueryRenderer
          query={query}
          render={({ error, props }) => {
            if (error) {
              return <div>Error!</div>;
            }
            if (!props) {
              return <div>Loading...</div>;
            }
            return <ListingTable columns={columns} listData={props} />;
          }}
          variables={filters}
        />
      </Row>
    </Container>
  );
};
