import { CSSProperties, FC } from "react";

import { css, styled } from "providers/theme";

const Component: FC<{ className?: string; style?: CSSProperties }> = ({ children, ...props }) => {
  return (
    <div {...props} style={{ ...(props.style || {}), display: undefined }}>
      <div>{children}</div>
    </div>
  );
};

export const Td: FC = styled(Component)`
  justify-content: flex-start;
  padding: 0.25rem 0.5rem;
  display: inline-flex;
  align-items: center;
  text-align: left;

  ${({ style }) =>
    style &&
    style?.width &&
    css`
      width: ${style.width};
      min-width: ${style.width};
    `}
  &.expand {
    flex: 1;
    width: auto !important;
  }

  & > div {
    display: block;
    max-width: 100%;
  }
`;
