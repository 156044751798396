import { useMemo } from "react";
import * as Yup from "yup";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useSchema = () => {
  return useMemo(() => {
    const profile = Yup.object({
      about: Yup.string().nullable().default(null),
      birthday: Yup.date().nullable().default(null),
      firstName: Yup.string().max(50).nullable().default(null),
      lastName: Yup.string().max(50).nullable().default(null),
      middleName: Yup.string().max(50).nullable().default(null),
      nameday: Yup.date().nullable().default(null),
      profilePhoto: Yup.mixed().nullable().default(null),
    }).defined();

    const workData = Yup.object({
      position: Yup.string().nullable().default(null),
    }).defined();

    const address = Yup.object({
      city: Yup.string().nullable().default(null),
      country: Yup.string().nullable().default(null),
      street: Yup.string().nullable().default(null),
      zip: Yup.string().nullable().default(null),
    }).defined();

    return Yup.object({
      address: address.default(address.cast({})),
      profile: profile.default(profile.cast({})),
      workData: workData.default(workData.cast({})),
    });
  }, []);
};

export type ProfileFormData = Yup.InferType<ReturnType<typeof useSchema>>;
