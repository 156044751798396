import { FC } from "react";
import { useRouteMatch } from "react-router-dom";

import { LoadingIndicator } from "components";
import { QueryRenderer, clientDetailQuery } from "providers";
import type { clientDetailQuery as clientDetailQueryType } from "providers/Api/__generated__/clientDetailQuery.graphql";

import { ClientDetail } from "./ClientDetail";

export const ClientDetailPage: FC = () => {
  const {
    params: { id },
  } = useRouteMatch<{ id: string }>();

  return (
    <QueryRenderer<clientDetailQueryType>
      query={clientDetailQuery}
      // @ts-ignore TODO: improve typings
      render={renderQuery}
      variables={{ id }}
    />
  );
};

const renderQuery = ({
  error,
  props,
}: {
  error?: Error;
  props: clientDetailQueryType["response"];
}) => {
  if (error) {
    // TODO: err page
    return <div>{error.message}</div>;
  } else if (props && props.client) {
    return <ClientDetail client={props.client} />;
  }
  return <LoadingIndicator />;
};
