import { FC } from "react";

import { Container } from "components";
import { AddressFieldset } from "components/Form2";

export const Address: FC = () => {
  return (
    <Container>
      <AddressFieldset />
    </Container>
  );
};
