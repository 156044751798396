/**
 * Define app navigation links
 */
export const appNavigation = {
  clientDetail: (id: ID) => `/clients/${id}`,
  clientList: () => "/clients",
  contactList: () => "/contacts",
  dev: () => "/dev-tools",
  files: () => "/files",
  forgotPassword: () => "/forgot-password",
  home: () => "/",
  login: () => "/login",
  logout: () => "/logout",
  myCompany: () => "/my-company",
  myProfile: () => "/my-profile",
  projects: () => "/projects",
  resetPassword: () => "/reset-password",
  signUp: () => "/sign-up",
  userDetail: (id: ID) => `/users/${id}`,
  userList: () => "/users",
} as const;

/**
 * Define custom routes for react-router
 */
export const customRoutes = { clientDetail: "/clients/:id", userDetail: "/users/:id" } as const;
