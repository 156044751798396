import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import { FC, useMemo, useCallback } from "react";
import { Helmet } from "react-helmet";
import * as Yup from "yup";

import { Form, Icon, Tabs, TabContent } from "components";
import { getHistory, t } from "helpers";

import type { SectionDefinition } from "./DetailPageLayout";

interface Props {
  // cast form initials
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cast?: (e: any) => any;
  entity?: null | Record<string, unknown>;
  error?: Error;
  listRoute: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSave?: (data: any, props: any) => void;
  schema: Yup.AnySchema;
  sections?: SectionDefinition[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  title?: (e: any) => string;
}

export const DetailPage: FC<Props> = ({
  cast,
  entity,
  listRoute,
  onSave,
  schema,
  sections,
  title,
}) => {
  // @ts-ignore TODO: add generic
  const tabs = useMemo(() => sections || [], [sections]);
  const inits = useMemo(
    () => schema.cast(cast ? cast(entity || {}) : entity || {}, { stripUnknown: true }),
    [entity, schema, cast]
  );

  const handleSubmit = useCallback(
    (data: Yup.InferType<typeof schema>) => {
      onSave && onSave(data, { entity });
    },
    [onSave, entity]
  );

  const pageTitle = title && title(entity);

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <div className="p-4">
        <div className="d-flex justify-content-between mb-3">
          <div className="d-flex flex-row align-items-center">
            <IconButton
              color="primary"
              title={t("Back to list")}
              onClick={() => getHistory().push(listRoute)}
            >
              <Icon name="arrow-left" />
            </IconButton>
            <h2 className="m-0 ml-3">{pageTitle}</h2>
          </div>
          <div>
            <IconButton color="primary" form="detail-page" title={t("Save")} type="submit">
              <Icon name="save" />
            </IconButton>
          </div>
        </div>

        <Tabs className="mb-4" name="card" options={tabs} preselect="profile" />

        <Paper className="py-5 px-2" elevation={5}>
          <Form id="detail-page" initialValues={inits} schema={schema} onSubmit={handleSubmit}>
            {sections?.map(({ component: C, key }) => (
              <TabContent key={key} name="card" tab={key}>
                <C />
              </TabContent>
            ))}
          </Form>
        </Paper>
      </div>
    </>
  );
};
