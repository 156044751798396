import UiButton, { ButtonProps as UiButtonProps } from "@material-ui/core/Button";
import moment from "moment";
import { FC } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";

import logo from "assets/images/logo.png";
import { Icon } from "components";
import { UserDropdown } from "containers/UserDropdown";
import { t, appNavigation, cn } from "helpers";
import { useSession } from "providers/AuthProvider";

import { style } from "./AppLayout.styled";
export interface AppLayoutProps {
  className?: string;
  contentClass?: string;
}

moment.locale("sk"); // TODO: connect with locale context

const Component: FC<AppLayoutProps> = ({ children, className, contentClass }) => {
  const session = useSession();
  const { location } = useHistory();
  const path = location.pathname;

  // TODO: use user + get avatar

  return (
    <div className={className}>
      <div role="grid">
        <aside>
          <Link className="brand" title={t("Home")} to={appNavigation.home()}>
            <img
              alt={t("Evoneto logo")}
              arial-label={t("Go to Home page")}
              src={logo}
              title={t("Evoneto Home")}
              width="32"
            />
            <div>
              <span className="d-block">{t("evoneto")}</span>
              <small>{t("Office reinvented")}</small>
            </div>
          </Link>

          <Button
            fullWidth
            className={cn({ active: path === appNavigation.userList() })}
            href={appNavigation.userList()}
            size="large"
            variant="text"
          >
            <Icon className="mr-2" name="users" />
            {t("Users")}
          </Button>

          <Button
            fullWidth
            className={cn({ active: path === appNavigation.projects() })}
            href={appNavigation.projects()}
            size="large"
            variant="text"
          >
            <Icon className="mr-2" name="list" />
            {t("Projects")}
          </Button>

          <Button
            fullWidth
            className={cn({ active: path === appNavigation.clientList() })}
            href={appNavigation.clientList()}
            size="large"
            variant="text"
          >
            <Icon className="mr-2" name="business" />
            {t("Clients")}
          </Button>

          <Button
            fullWidth
            className={cn({ active: path === appNavigation.contactList() })}
            href={appNavigation.contactList()}
            size="large"
            variant="text"
          >
            <Icon className="mr-2" name="contacts" />
            {t("Contacts")}
          </Button>

          <Button
            fullWidth
            className={cn({ active: path === appNavigation.files() })}
            href={appNavigation.files()}
            size="large"
            variant="text"
          >
            <Icon className="mr-2" name="folder" />
            {t("Files")}
          </Button>

          <Button
            fullWidth
            className={cn("mt-auto", { active: path === appNavigation.myCompany() })}
            href={appNavigation.myCompany()}
            size="large"
            variant="text"
          >
            <Icon className="mr-2" name="briefcase" />
            {session?.tenant.name}
          </Button>

          <Button
            fullWidth
            className={cn({ active: path === appNavigation.dev() })}
            href={appNavigation.dev()}
            size="large"
            variant="text"
          >
            <Icon className="mr-2" name="graphql" />
            {t("Graphiql")}
          </Button>

          <UserDropdown />
        </aside>
        <main className={contentClass}>{children}</main>
      </div>
    </div>
  );
};

export const AppLayout = styled(Component)<AppLayoutProps>`
  ${style}
`;

const Button: FC<UiButtonProps> = ({ href, ...props }) => {
  const history = useHistory();

  return (
    // @ts-ignore TODO: fix typings
    <UiButton
      {...props}
      className={cn(props.className, "justify-content-start")}
      onClick={(e) => {
        e.preventDefault();
        href && history.push({ pathname: href });
      }}
    />
  );
};
