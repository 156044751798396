import { FC, RefObject } from "react";

import { css, styled } from "providers/theme";

interface Position {
  bottom?: number;
  left?: number;
  ref?: RefObject<HTMLDivElement | undefined>;
  right?: number;
  top?: number;
}

export const Container: FC<Position> = styled.div<Position>`
  position: absolute;
  z-index: 1000;
  ${({ bottom, left, right, top }) => css`
    bottom: ${bottom ? bottom + "px" : "auto"};
    right: ${right ? right + "px" : "auto"};
    left: ${left ? left + "px" : "auto"};
    top: ${top ? top + "px" : "auto"};
  `}
`;

export const Content = styled.div`
  border: 1px solid ${({ theme }) => theme.palette.gray.main};
  background: ${({ theme }) => theme.palette.light.main};
  border-radius: ${({ theme }) => theme.radius.normal};
  padding: 0;
`;
