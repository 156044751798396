/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type clientPaginationQueryVariables = {
    first?: number | null;
    after?: string | null;
    search?: string | null;
};
export type clientPaginationQueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"client_pagination">;
};
export type clientPaginationQuery = {
    readonly response: clientPaginationQueryResponse;
    readonly variables: clientPaginationQueryVariables;
};



/*
query clientPaginationQuery(
  $first: Int
  $after: String
  $search: String
) {
  ...client_pagination_1Ozsmw
}

fragment client_pagination_1Ozsmw on Query {
  list: clients(first: $first, after: $after, filter: {search: $search}) {
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasNextPage
    }
    edges {
      cursor
      node {
        id
        name
        bussinessId
        __typename
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v3 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after"
},
v4 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v5 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search"
},
v6 = [
  (v3/*: any*/),
  {
    "fields": [
      (v5/*: any*/)
    ],
    "kind": "ObjectValue",
    "name": "filter"
  },
  (v4/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "clientPaginationQuery",
    "selections": [
      {
        "args": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "client_pagination"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "clientPaginationQuery",
    "selections": [
      {
        "alias": "list",
        "args": (v6/*: any*/),
        "concreteType": "ClientPagination",
        "kind": "LinkedField",
        "name": "clients",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PaginationPageInfoType",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ClientPaginationEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Client",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "bussinessId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "list",
        "args": (v6/*: any*/),
        "filters": [
          "filter"
        ],
        "handle": "connection",
        "key": "Client_list",
        "kind": "LinkedHandle",
        "name": "clients"
      }
    ]
  },
  "params": {
    "cacheID": "999cb5dd73aeb837c02fa791a6ef8573",
    "id": null,
    "metadata": {},
    "name": "clientPaginationQuery",
    "operationKind": "query",
    "text": "query clientPaginationQuery(\n  $first: Int\n  $after: String\n  $search: String\n) {\n  ...client_pagination_1Ozsmw\n}\n\nfragment client_pagination_1Ozsmw on Query {\n  list: clients(first: $first, after: $after, filter: {search: $search}) {\n    totalCount\n    pageInfo {\n      startCursor\n      endCursor\n      hasNextPage\n    }\n    edges {\n      cursor\n      node {\n        id\n        name\n        bussinessId\n        __typename\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '5bb91df8941f7a6322af2715b7933c2f';
export default node;
