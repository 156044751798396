import { FC } from "react";
import * as Yup from "yup";

import { Col, Row, Container } from "components/common/Grid";
import { Field } from "components/Form/Field";
import { TextInput } from "components/inputs/TextInput";
import { t } from "helpers";

export const AddressFieldset: FC<{ prefix?: string }> = ({ prefix = "address" }) => {
  return (
    <Container>
      <Row>
        <Col className="mb-4" md={12} sm={12} xs={12}>
          <Field component={TextInput} label={t("Street")} name={`${prefix}.street`} />
        </Col>

        <Col className="mb-4" md={4} sm={6} xs={12}>
          <Field component={TextInput} label={t("City")} name={`${prefix}.city`} />
        </Col>

        <Col className="mb-4" md={4} sm={6} xs={12}>
          <Field component={TextInput} label={t("Zip")} name={`${prefix}.zip`} />
        </Col>

        <Col className="mb-4" md={4} sm={6} xs={12}>
          <Field component={TextInput} label={t("Country")} name={`${prefix}.country`} />
        </Col>
      </Row>
    </Container>
  );
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useAddressFieldsetSchema() {
  const schema = Yup.object({
    city: Yup.string().nullable().default(null),
    country: Yup.string().nullable().default(null),
    street: Yup.string().nullable().default(null),
    zip: Yup.string().nullable().default(null),
  }).defined();

  return schema.defined(schema.cast({}));
}

export type AddressFieldsetType = Yup.InferType<ReturnType<typeof useAddressFieldsetSchema>>;
