import { FC } from "react";

import { Container, Col, Row, Field } from "components";
import { TextInput } from "components/inputs/TextInput";
import { t } from "helpers";

export const PersonalDetails: FC = () => {
  return (
    <Container>
      <Row className="mb-4">
        <Col md={4} sm={6} xs={12}>
          <Field component={TextInput} label={t("First name")} name="profile.firstName" />
        </Col>
        <Col md={4} sm={6} xs={12}>
          <Field component={TextInput} label={t("Middle name")} name="profile.middleName" />
        </Col>
        <Col md={4} sm={6} xs={12}>
          <Field component={TextInput} label={t("Last name")} name="profile.lastName" />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <Field
            component={TextInput} // TODO: switch to date
            label={t("Birth date")}
            maxDate={new Date()}
            name="profile.birthday"
          />
        </Col>
        {/* <Col>
          <FormikConsumer>
            {({
              values: {
                profile: { birthday },
              },
              setFieldValue,
            }) => {
              return (
                <Field
                  label={t("Age")}
                  name="profile.age"
                  component={TextField}
                  InputProps={{
                    endAdornment: (
                      <Tooltip title={t("Calculate from Birth-date")}>
                        <InputAdornment position="end">
                          <IconButton
                            disabled={!birthday}
                            onClick={() =>
                              birthday &&
                              setFieldValue(
                                "profile.age",
                                Math.max(1, new Date().getFullYear() - birthday.getFullYear())
                              )
                            }
                          >
                            <Icon name="calculate" />
                          </IconButton>
                        </InputAdornment>
                      </Tooltip>
                    ),
                  }}
                />
              );
            }}
          </FormikConsumer>
        </Col> */}
        <Col>
          <Field
            disableToolbar
            component={TextInput}
            format="DD. MMM"
            label={t("Name day")}
            name="profile.nameday"
            openTo="month"
            views={["month", "date"]}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field multiline component={TextInput} label={t("Bio")} name="profile.about" rows={3} />
        </Col>
      </Row>
    </Container>
  );
};
