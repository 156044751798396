import * as Yup from "yup";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useSchema() {
  const string = Yup.string().nullable().default(null);

  const clientInfo = Yup.object({
    discount: Yup.number().nullable().default(null),
    ico: string,
    name: string,
    note: string,
    taxId: string,
    type: string,
    vat: string,
  });

  return Yup.object({
    info: clientInfo.default(clientInfo.cast({})),
  });
}

export type ClientData = Yup.InferType<ReturnType<typeof useSchema>>;
