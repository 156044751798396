import UploadIcon from "@material-ui/icons/CloudUpload";
import { makeStyles } from "@material-ui/styles";
import { FC } from "react";
import ReactDropzone from "react-dropzone";

import { t } from "helpers";

interface Props {
  accept?: string[];
  height?: number;
  multiple?: boolean;
  onChange?: (value: File | File[]) => void;
  width?: number;
}

export const Dropzone: FC<Props> = ({
  width = 320,
  height = 320,
  onChange,
  multiple = false,
  accept = [],
}) => {
  const classes = useStyles({ height, width });
  const acc = typeof accept === "string" ? accept : (accept || []).join(",");
  return (
    <ReactDropzone
      onDrop={(acceptedFiles) => {
        onChange && onChange(multiple ? acceptedFiles : acceptedFiles[0]);
      }}
      // accept={accept}
    >
      {({ getInputProps, getRootProps }) => {
        const inputProps = getInputProps();
        return (
          <div {...getRootProps()} className={classes.root}>
            <input {...inputProps} accept={acc} />

            <div className={classes.placeholder}>
              <UploadIcon className={classes.icon} />
              <h3>{t("Insert")}</h3>
              <span>
                {t("your files here,", {
                  files: accept.map((i) => (i + "/" + i).split("/")[1]).join(", "),
                })}
                <br />
                {t("or")}
                <a href="#drag&drop">{t("select")}</a>
              </span>
            </div>
          </div>
        );
      }}
    </ReactDropzone>
  );
};

const color = "#ccc";

const useStyles = makeStyles(() => ({
  icon: {
    color,
    fontSize: "3rem",
  },
  placeholder: {
    "& a": {
      color,
    },
    alignItems: "center",
    border: `3px dashed ${color}`,
    borderRadius: "1rem",
    color: color + " !important",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "center",
    minHeight: 200,
    minWidth: 200,
    outline: "none",
    textAlign: "center",
    width: "100%",
  },
  root: {
    cursor: "grab",
    height: (props: Props) => props.height || "auto",
    margin: "0 auto",
    maxHeight: (props: Props) => props.height || "auto",
    maxWidth: (props: Props) => props.width || "auto",
    outline: "none",
    width: (props: Props) => props.width || "auto",
  },
  title: {
    color: "#666",
  },
}));
