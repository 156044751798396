import { FC } from "react";

import svg from "assets/images/loading.svg";
import { t } from "helpers";
import { styled } from "providers";

export const Component: FC<{ className?: string }> = ({ className }) => {
  return (
    <div className={className}>
      <img alt={t("Loading animation")} src={svg} />
    </div>
  );
};

export const LoadingIndicator = styled(Component)`
  height: 100%;
  width: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 5rem;
    height: 5rem;
  }
`;
