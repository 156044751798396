import { FC } from "react";

import { Container, Col, Row } from "components";
import { AddressFieldset } from "components/Form2";
import { t } from "helpers";

export const AddressesSection: FC = () => {
  return (
    <Container>
      <Row>
        <Col className="mb-2" md={12} sm={12} xs={12}>
          <h2>{t("Company Headquarters")}</h2>
        </Col>

        <AddressFieldset prefix="address" />

        <Col className="mb-2" md={12} sm={12} xs={12}>
          <h2>{t("Office")}</h2>
        </Col>

        <AddressFieldset prefix="office" />

        <Col className="mb-2" md={12} sm={12} xs={12}>
          <h2>{t("Delivery")}</h2>
        </Col>

        <AddressFieldset prefix="delivery" />
      </Row>
    </Container>
  );
};
