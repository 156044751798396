import UIAvatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/styles";
import { FC, useState, useRef, useEffect } from "react";

interface Props {
  alt?: string;
  onClick?: () => void;
  size?: number;
  value: string;
}

export const Avatar: FC<Props> = ({ alt = "avatar", onClick, size = 24, value }) => {
  const [url, setUrl] = useState<string>();
  const valRef = useRef(value);

  useEffect(() => {
    if ((!url || valRef.current !== value) && value && typeof value !== "string") {
      toBase64(value).then(setUrl);
      valRef.current = value;
    } else if ((!url || valRef.current !== value) && typeof value === "string") {
      setUrl(value);
      valRef.current = value;
    }
  }, [url, value, setUrl]);

  const I: FC<{ url: string }> = ({ url }) => (
    <UIAvatar
      alt={alt}
      // @ts-ignore TODO: inspect problem
      className={classes.root}
      component="span"
      src={url}
      onClick={onClick}
    />
  );

  // @ts-ignore TODO: fix
  const classes = useStyles({ onClick, size });
  return <I url={url || ""} />;
};

const useStyles = makeStyles(
  // @ts-ignore TODO: inspect problem
  () =>
    ({
      root: {
        cursor: (props: Props) => (props.onClick ? "pointer" : null),
        height: (props: Props) => props.size,
        width: (props: Props) => props.size,
      },
    } as const)
);

const toBase64 = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve((reader.result && reader.result.toString()) || "");
    reader.onerror = (error) => reject(error);
  });
