import cn from "classnames";
import { FC } from "react";

interface Props {
  className?: string;
}

export const Row: FC<Props> = ({ children, className }) => (
  <div className={cn("row d-flex", className, {})}>{children}</div>
);
