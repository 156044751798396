import { ThemeProvider as MuiThemeProvider } from "@material-ui/core";
import { FC } from "react";
import { Router, Route, Switch, Redirect } from "react-router";
import { ToastContainer } from "react-toastify";
import { ThemeProvider } from "styled-components/macro";

import "react-toastify/dist/ReactToastify.css";

import { LoadingIndicator } from "components";
import { appRoutes, getHistory } from "helpers";
import { AppLayout } from "layouts";
import {
  DevPage,
  LoginPage,
  SignUpPage,
  LogoutPage,
  ProfilePage,
  ResetPasswordPage,
  ForgotPasswordPage,
} from "pages";
import { ClientDetailPage } from "pages/clients/ClientDetailPage";
import { ClientListPage } from "pages/clients/ClientsListPage";
import { ContactListPage } from "pages/clients/ContactListPage";
import { MyCompanyPage } from "pages/MyCompanyPage";
import { ProjectsListPage } from "pages/projects";
import { UsersListPage, UserDetailPage } from "pages/users";
import { ApiEnvProvider } from "providers";
import { getTheme, GlobalStyle, AuthProvider, Can } from "providers";

const theme = getTheme();

export const App: FC = () => (
  <ThemeProvider theme={theme}>
    <MuiThemeProvider theme={theme}>
      <GlobalStyle />
      <ApiEnvProvider>
        <Router history={getHistory()}>
          <AuthProvider fallback={<LoadingIndicator />}>
            <>
              <Switch>
                <Route component={DevPage} path={appRoutes.dev} />

                <Route component={LoginPage} path={appRoutes.login} />
                <Route component={SignUpPage} path={appRoutes.signUp} />
                <Route component={ForgotPasswordPage} path={appRoutes.forgotPassword} />
                <Route component={ResetPasswordPage} path={appRoutes.resetPassword} />
                <Route component={LogoutPage} path={appRoutes.logout} />

                <Can fallback={<Redirect to={appRoutes.login} />}>
                  <AppLayout>
                    <Switch>
                      <Route exact component={ProjectsListPage} path={appRoutes.projects} />

                      <Route exact component={ClientListPage} path={appRoutes.clientList} />
                      <Route component={ClientDetailPage} path={appRoutes.clientDetail} />
                      <Route exact component={ContactListPage} path={appRoutes.contactList} />

                      <Route exact component={UsersListPage} path={appRoutes.userList} />
                      <Route component={UserDetailPage} path={appRoutes.userDetail} />

                      {/* Session Specific  */}
                      <Route component={MyCompanyPage} path={appRoutes.myCompany} />
                      <Route component={ProfilePage} path={appRoutes.myProfile} />

                      <Route component={DevNull} />
                    </Switch>
                  </AppLayout>
                </Can>
              </Switch>
              <ToastContainer />
            </>
          </AuthProvider>
        </Router>
      </ApiEnvProvider>
    </MuiThemeProvider>
  </ThemeProvider>
);

const DevNull: FC = () => <>404</>;
