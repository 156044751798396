import op from "object-path";
import { useContext } from "use-context-selector";
import { useContextSelector } from "use-context-selector";

import { InputInterface } from "components/inputs";

import { FormActionContext, FormStateContext } from "./FormContext";

export function useField(name: string): InputInterface {
  // use selector to optimize values
  const { changed, errors, submitted, touched, values } = useContext(FormStateContext);
  const dispatch = useContext(FormActionContext);

  const value = op.get(values, name);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChange = (value: any) => dispatch({ name, type: "onChange", value });

  const onBlur = () => dispatch({ name, type: "onBlur" });
  const isTouched = submitted > 0 || !!touched[name];
  // display error on-blur
  const error = isTouched && !!errors[name];

  return {
    changed: !!changed[name],
    error,
    helperText: error ? errors[name] : undefined,
    name,
    onBlur,
    onChange,
    touched: isTouched,
    value,
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useFieldValue<V = any>(name: string): V {
  return useContextSelector(FormStateContext, (v) => v.values[name]);
}
