/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type UserInput = {
    userUuid?: string | null;
    roleUuid?: string | null;
    profile?: ProfileInput | null;
    workData?: WorkDataInput | null;
    address?: AddressInput | null;
};
export type ProfileInput = {
    userProfileUuid?: string | null;
    firstName?: string | null;
    middleName?: string | null;
    lastName?: string | null;
    title?: string | null;
    phone?: string | null;
    about?: string | null;
    birthday?: unknown | null;
    nameday?: unknown | null;
    gender?: string | null;
    profilePhoto?: unknown | null;
};
export type WorkDataInput = {
    userWorkDataUuid?: string | null;
    personalId?: string | null;
    cardId?: string | null;
    pin?: string | null;
    gdpr?: boolean | null;
    position?: string | null;
    supervisor?: string | null;
    department?: string | null;
    employedFrom?: unknown | null;
    employmentType?: string | null;
    employmentTrialPeriod?: unknown | null;
    lastEmploymentDataDateChange?: unknown | null;
    grossWage?: number | null;
    superGrossWage?: number | null;
};
export type AddressInput = {
    addressUuid?: string | null;
    street?: string | null;
    city?: string | null;
    zip?: string | null;
    country?: string | null;
};
export type userInviteMutationVariables = {
    email: string;
    user: UserInput;
};
export type userInviteMutationResponse = {
    readonly inviteUser: boolean | null;
};
export type userInviteMutation = {
    readonly response: userInviteMutationResponse;
    readonly variables: userInviteMutationVariables;
};



/*
mutation userInviteMutation(
  $email: String!
  $user: UserInput!
) {
  inviteUser(email: $email, user: $user)
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "email"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "user"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "email",
        "variableName": "email"
      },
      {
        "kind": "Variable",
        "name": "user",
        "variableName": "user"
      }
    ],
    "kind": "ScalarField",
    "name": "inviteUser",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "userInviteMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "userInviteMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "fc30af0db5533a380ffabba47eaa0cca",
    "id": null,
    "metadata": {},
    "name": "userInviteMutation",
    "operationKind": "mutation",
    "text": "mutation userInviteMutation(\n  $email: String!\n  $user: UserInput!\n) {\n  inviteUser(email: $email, user: $user)\n}\n"
  }
};
})();
(node as any).hash = '1b51ad676c1e421f585c4e5eb3c7d6cc';
export default node;
