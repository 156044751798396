import Tab from "@material-ui/core/Tab";
import MuiTabs from "@material-ui/core/Tabs";
import { useMemo, FC, useEffect } from "react";

import { getHistory, stringify, useQueryParams } from "helpers";
import { styled } from "providers";

import { styles } from "./styles";

interface TabOption {
  /** Should be unique */
  key: string;
  /** Display label */
  label: string;
}

interface Props {
  /** Class name override */
  className?: string;
  /** query param name */
  name?: string;
  /** Tabs to render */
  options: TabOption[];
  /** Default value when nothing is selected */
  preselect?: string;
}

const Component: FC<Props> = ({ className, name, options, preselect }) => {
  const params = useQueryParams();
  const tabName = name || "tab";
  const tab = params[tabName];

  const active =
    (tab || preselect ? options.findIndex((i) => i.key === (tab ? tab : preselect)) : 0) || 0;

  const tabs = useMemo(() => options.map(({ key, label }) => <Tab key={key} label={label}></Tab>), [
    options,
  ]);

  useEffect(() => {
    if (preselect && !tab) {
      getHistory().replace({ search: stringify({ [tabName]: preselect }) });
    }
  }, [tab, tabName, preselect]);

  const setTab = (tab: string) => {
    getHistory().push({ search: stringify({ [tabName]: tab }) });
  };

  return (
    <MuiTabs
      className={className}
      indicatorColor="primary"
      scrollButtons="auto"
      value={active}
      variant="scrollable"
      onChange={(_e, i) => options[Number(i)] && setTab(options[Number(i)].key)}
    >
      {tabs}
    </MuiTabs>
  );
};

export const Tabs = styled(Component)<Props>`
  ${styles}
`;
