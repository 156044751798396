import { createContext } from "use-context-selector";
import * as Yup from "yup";

export interface FormState<D extends Data> {
  changed: Record<string, boolean>;
  errors: Record<string, string>;
  isValid: boolean;
  isValidating: boolean;
  schema?: Yup.SchemaOf<D>;
  submitted: number;
  touched: Record<string, boolean>;
  values: D;
}

export type FormAction<D extends Data = Data> =
  | { name: string; type: "onChange"; value: unknown }
  | { name: string; type: "onBlur" }
  | { cb: (data: D) => void; type: "onSubmit" };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Data = { [key: string]: any };

export const initialState = {
  changed: {},
  errors: {},
  isValid: true,
  isValidating: false,
  submitted: 0,
  touched: {},
  values: {},
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const FormStateContext = createContext<FormState<any>>(initialState);
export const FormActionContext = createContext<(action: FormAction) => void>(() => undefined);
