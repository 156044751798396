import { css, styled } from "providers/theme";

import { Td } from "./Td.styled";

export const Tr = styled.div<{ className?: string; onClick?: unknown }>`
  width: 100% !important;
  flex-direction: row;
  text-align: center;
  padding: 0 0.5rem;
  display: flex;

  &.even {
    background: ${({ theme }) => theme.palette.gray.light};
  }

  min-height: 3rem;

  &.dense {
    min-height: 2rem;
  }

  & > ${Td as never} {
    align-items: center;

    & > div {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &:not(.expand-row) > ${Td as never} {
    & > div {
      white-space: nowrap;
    }
  }

  ${({ onClick }) =>
    typeof onClick === "function" &&
    css`
      cursor: pointer;
    `}
`;
