import { styled } from "providers/theme";

export const Field = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  min-height: 2.5rem;
  overflow: hidden;

  input {
    border: none;
    width: auto;
    flex: 1;
  }
`;
