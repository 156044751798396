import { FC } from "react";

import { Container, Col, Row, Field } from "components";
import { DateInput } from "components/inputs/DateInput";
import { TextInput } from "components/inputs/TextInput";
import { t } from "helpers";

export const WorkData: FC = () => {
  return (
    <Container>
      <Row className="mb-4">
        <Col md={4} sm={6} xs={12}>
          <Field component={TextInput} label={t("Position")} name="workData.position" />
        </Col>
        <Col md={4} sm={6} xs={12}>
          <Field component={TextInput} label={t("Personal Id")} name="workData.personalId" />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col md={4} sm={6} xs={12}>
          <Field component={TextInput} label={t("CardData")} name="workData.cardId" />
        </Col>
        <Col md={4} sm={6} xs={12}>
          <Field component={TextInput} label={t("Pin")} name="workData.pin" />
        </Col>
      </Row>

      <Row className="mb-4">
        <Col md={4} sm={6} xs={12}>
          <Field component={TextInput} label={t("Employment")} name="workData.employmentType" />
        </Col>
        <Col md={4} sm={6} xs={12}>
          <Field
            component={DateInput}
            label={t("Commencement of employment")}
            maxDate={new Date()}
            name="workData.employedFrom"
          />
        </Col>
        <Col md={4} sm={6} xs={12}>
          <Field
            component={DateInput}
            label={t("Trial period")}
            maxDate={new Date()}
            name="workData.employedFrom"
          />
        </Col>
      </Row>

      <Row className="mb-4">
        <Col sm={6} xs={12}>
          <Field component={TextInput} label={t("Gross wage")} name="workData.grossWage" />
        </Col>
        <Col sm={6} xs={12}>
          <Field
            component={TextInput}
            label={t("Super-gross wage")}
            name="workData.superGrossWage"
          />
        </Col>
      </Row>
    </Container>
  );
};
