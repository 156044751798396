import { FC } from "react";

import { styled } from "providers";

export const SidePanel: FC = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 2rem 1rem;
  width: 15rem;
  background: ${({ theme }) => theme.palette.gray.light};
`;
