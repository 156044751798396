import { Button, FormHelperText } from "@material-ui/core";
import { FC, useCallback } from "react";
import { useMutation } from "react-relay/hooks";
import { Link, Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";

import { Icon, Form, Field } from "components";
import { TextInput } from "components/inputs/TextInput";
import { appNavigation, t, useQueryParams } from "helpers";
import { AuthLayout } from "layouts";
import { publicResetPasswordMutation } from "providers/Api";
import type { publicResetPasswordMutation as publicResetPasswordMutationType } from "providers/Api/__generated__/publicResetPasswordMutation.graphql";
import { useSetSession } from "providers/AuthProvider";

export const ResetPasswordPage: FC = () => {
  const setSession = useSetSession();
  const onCompleted = useCallback(
    (res: publicResetPasswordMutationType["response"]) => {
      if (res.resetPassword && res.resetPassword.user) {
        toast.success(t("Your new password was set."));
        setSession(res.resetPassword);
      }
    },
    [setSession]
  );

  const { token } = useQueryParams();
  const [dispatch] = useMutation<publicResetPasswordMutationType>(publicResetPasswordMutation);

  const schema = Yup.object()
    .shape({
      newPassword: Yup.string().nullable().required().min(8).default(null),
      token: Yup.string().nullable().required().default(null),
    })
    .defined();

  const inits = schema.cast({ token });

  type FormData = Yup.InferType<typeof schema>;

  const handleReset = (reset: FormData) => {
    dispatch({
      onCompleted,
      onError: (error) => {
        error && toast.error(t("Oops, {msg}", { msg: error.message || error }));
      },
      variables: { reset },
    });
  };

  if (!token) {
    return <Redirect to={appNavigation.login()} />;
  }

  return (
    <AuthLayout>
      <Form<FormData>
        id="forgotPassword"
        initialValues={inits}
        schema={schema}
        onSubmit={handleReset}
      >
        <h2 className="mb-5">{t("Reset password?")}</h2>

        <FormHelperText className="mb-3">{t("Enter new password.")}</FormHelperText>

        <Field
          fullWidth
          className="mb-3"
          component={TextInput}
          label={t("Password")}
          name="newPassword"
          type="password"
          variant="standard"
        />

        <Button fullWidth className="mb-3" color="primary" type="submit" variant="contained">
          {t("Set new password")}
        </Button>

        <Link to={appNavigation.login()}>
          <Icon name="arrow-left" /> {t("Back to login")}
        </Link>
      </Form>
    </AuthLayout>
  );
};
