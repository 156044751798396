import { css } from "providers/theme";

import type { ButtonProps } from "./Button";

export const buttonSizeMixin = css<{ size?: "small" | "normal" | "large" }>`
  height: ${({ size }) => (size === "large" ? "2.5rem" : size === "small" ? "1.5rem" : "2rem")};
  padding: ${({ size }) =>
    size === "large" ? "0 1rem" : size === "small" ? "0 0.5rem" : "0 0.75rem"};
  font-size: ${({ size }) =>
    size === "large" ? "1.2rem" : size === "small" ? "0.65rem" : "0.875rem"};
  min-width: ${(
    { size } // this is for Icon buttons
  ) => (size === "large" ? "2.5rem" : size === "small" ? "1.5rem" : "2rem")};
`;

interface ButtonRadiusProps {
  rounded?: boolean;
  size?: "small" | "normal" | "large";
}

export const buttonRadiusMixin = css<ButtonRadiusProps>`
  border-radius: ${({ rounded, size, theme }) =>
    rounded
      ? size === "large"
        ? "2.5rem"
        : size === "small"
        ? "1rem"
        : "2rem"
      : theme.radius.tiny};
`;

/** Button styles */
export const styles = css<ButtonProps>`
  text-transform: uppercase;
  border: 1px solid ${({ theme }) => theme.palette.gray.light};
  position: relative;
  overflow: hidden;
  transition: background 400ms;
  line-height: 0.875;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  box-sizing: border-box;
  vertical-align: center;

  ${buttonRadiusMixin}

  ${buttonSizeMixin}

  /* Contained variant */
  ${({ color, theme, variant }) =>
    (variant === "raised" || variant === "contained") &&
    css`
      background-color: ${color ? theme.palette[color].main : theme.palette.gray.light};
      border-color: ${color ? theme.palette[color].main : theme.palette.gray.light};
      color: ${color ? theme.palette[color].contrastText : theme.textColor};

      ${variant === "raised" &&
      css`
        box-shadow: ${({ theme }) => theme.shadow.button};
      `}

      &:hover {
        background-color: ${color ? theme.palette[color].dark : theme.palette.gray.dark};
        border-color: ${color ? theme.palette[color].dark : theme.palette.gray.dark};
      }
    `}

  /* Outlined variant */
    ${({ color, theme, variant }) =>
    variant === "outlined" &&
    css`
      background-color: transparent;
      color: ${theme.textColor};

      border-color: ${color ? theme.palette[color].main : theme.textColor};

      &:hover {
        border-color: ${color ? theme.palette[color].dark : theme.textColor};
        color: ${color ? theme.palette[color].main : theme.textColor};
      }
    `}

  /** Text variant */
  ${({ color, theme, variant }) =>
    variant === "text" &&
    css`
      color: ${color ? theme.palette[color].main : theme.textColor};
      background-color: transparent;
      border-color: transparent;
      &:hover {
        background-color: ${theme.palette.gray.light};
        border-color: ${theme.palette.gray.light};
      }
    `}


  /* Material click effect */ 
  span.ripple {
    position: absolute;
    border-radius: 50%;
    transform: scale(0);
    animation: ripple 600ms linear;
    background-color: rgba(255, 255, 255, 0.2);
  }

  @keyframes ripple {
    to {
      transform: scale(4);
      opacity: 0;
    }
  }
`;
