import { styled } from "providers/theme";

export const Field = styled.div`
  position: relative;
  padding: 0.5rem 0;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
`;

export const CheckMark = styled.div<{ className?: string }>`
  display: inline-flex;
  margin-right: 0.5rem;
  position: relative;
  width: 1rem;
  height: 1rem;
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.palette.gray.main};
  border-radius: ${({ theme }) => theme.radius.normal};
  align-items: center;
  justify-content: center;

  &.active {
    background-color: ${({ theme }) => theme.palette.primary.main};
    border-color: ${({ theme }) => theme.palette.primary.main};
    color: white;
  }
`;

export const Label = styled.label`
  position: relative;
  flex-direction: row;
  cursor: pointer;
  margin-bottom: 0;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;

  input:focus + ${CheckMark} {
    box-shadow: 0 0 0.3rem ${({ theme }) => theme.palette.primary.dark};

    &.active {
      box-shadow: 0 0 0.3rem ${({ theme }) => theme.palette.gray.dark};
    }
  }
`;

export const Input = styled.input`
  position: absolute;
  display: flex;
  flex-direction: row;
  opacity: 0;
`;
