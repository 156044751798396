import { FC } from "react";
import { RelayEnvironmentProvider } from "react-relay/hooks";
import { RelayEnvironmentProvider as HooksProvider } from "relay-hooks";
import { Environment } from "relay-runtime";

import { ApolloProvider } from "providers/ApolloProvider";

import { getGQLEnvironment } from "./getGQLEnvironment";

const env = getGQLEnvironment();

export const ApiEnvProvider: FC = ({ children }) => {
  return (
    // original relay context
    <RelayEnvironmentProvider environment={env}>
      <ApolloProvider>
        {/* community hooks lib context */}
        <HooksProvider environment={env}>{children}</HooksProvider>
      </ApolloProvider>
    </RelayEnvironmentProvider>
  );
};

export function getEnv(): Environment {
  return env;
}
