import qs from "qs";
import { useEffect, useRef, useState } from "react";

import { getHistory } from "./history";

/**
 * Stringify Query parameters
 * - centralized serializer
 * - adding ?
 * @param {Json} data
 */
export function stringify(data?: undefined | Record<string, unknown>): string {
  if (!data) {
    return "";
  }
  const val = qs.stringify(data, { indices: false });
  return val ? `?${val}` : "";
}

/**
 * Watch url search query-string params
 * @returns {Object}
 */
export function useQueryParams(): { [key: string]: string } {
  const [val, set] = useState<Record<string, string>>(getQueryParams());
  const h = useRef(getHistory()).current;

  useEffect(() => {
    return h.listen((s) => {
      set(parse(s.search));
    });
  }, [h]);

  return val;
}

/**
 * Watch specific url search query-string parameter
 * @returns {Object}
 */
export function useQueryParam(
  key: string,
  initialValue?: string | number | boolean
): undefined | string {
  const [val, set] = useState<undefined | string>(getQueryParam(key));

  const h = useRef(getHistory()).current;

  const mounted = useRef(false);

  useEffect(() => {
    if (mounted.current) {
      return;
    }

    mounted.current = true;

    if (val === undefined && initialValue !== undefined) {
      setQueryParam(key, initialValue);
    }

    return () => {
      mounted.current = false;
    };
  }, [val, initialValue, key]);

  useEffect(() => {
    return h.listen((s) => {
      const data = parse(s.search || "");
      set(data[key]);
    });
  }, [h, key]);

  return initialValue !== undefined && val === undefined && !mounted.current
    ? initialValue + ""
    : val;
}

/**
 * Update url query string param value
 */
export function getQueryParam(key: string): undefined | string {
  const data = getQueryParams();

  return data[key];
}

/**
 * read url search query params
 */
export function getQueryParams(): Record<string, string> {
  const { search } = getHistory().location;

  try {
    const data = parse(search);
    return data;
  } catch {
    return {};
  }
}

function parse(search: string) {
  try {
    return qs.parse(search.replace(/^[?]{1}/, "")) as {
      [key: string]: string;
    };
  } catch {
    return {};
  }
}

/**
 * update url search query params
 */
export function setQueryParams(params: Record<string, unknown>, replace = false): void {
  const history = getHistory();

  let search = qs.stringify({
    ...(replace
      ? // ignore old
        {}
      : // reuse old query-string params
        qs.parse(history.location.search.replace(/^[?]{1}/, ""))),
    // set overrides
    ...params,
  });

  search = search ? `?${search}` : "";

  const nextURL = `${history.location.pathname}${search}`;
  const nextTitle = "";
  const nextState = {};

  window.history.replaceState(nextState, nextTitle, nextURL);
}

let batchChanges: Record<string, number | string | boolean | undefined> | undefined = undefined;

/**
 * set url search query param
 */
export function setQueryParam(
  name: string,
  value: string | number | boolean | undefined,
  replace = false
): void {
  if (!batchChanges) {
    setTimeout(() => {
      const history = getHistory();

      if (!history) {
        return;
      }

      const values = JSON.parse(
        JSON.stringify({
          ...qs.parse(history.location.search.replace(/^[?]{1}/, "")),
          ...(batchChanges || {}),
        })
      );

      batchChanges = undefined;

      let search = qs.stringify(values);

      search = search ? `?${search}` : "";

      const nextURL = `${history.location.pathname}${search}`;

      history[replace ? "replace" : "push"](nextURL);
    }, 150);
  }

  batchChanges = {
    ...(batchChanges || {}),
    [name]: value !== undefined ? value + "" : value,
  };
}
