import { useMemo, ReactElement, ComponentType } from "react";
import {
  IdType,
  Column,
  useTable,
  useSortBy,
  PluginHook,
  useFilters,
  TableInstance,
  useBlockLayout,
  useColumnOrder,
  useResizeColumns,
} from "react-table";

import { RenderTable } from "./components/RenderTable";
import { RenderTableBase } from "./components/RenderTableBase";
import { RenderVirtualTable } from "./components/RenderVirtualTable";
import { RefreshProvider } from "./components/useRefresh";

/**
 * Data table component
 *
 * TODO: refactor options
 */
export interface DataTableProps<T extends Record<string, unknown>> {
  columns: Array<Column<T> & { disableSortBy?: boolean; id: IdType<T>; show?: boolean }>;
  data: T[];
  dense?: boolean;
  expandRows?: boolean;
  isLoading?: boolean;
  loadMore?: (next: number) => Promise<void>;
  navbar?: ComponentType<{
    count: number;
    loadMore?: (next: number) => Promise<void>;
    table: TableInstance<T>;
    total: number;
  }>;
  onRowClick?: (row: T, index: number) => void;
  orderColumns?: boolean;
  resizableColumns?: boolean;
  sortable?: boolean;
  total: number;
  virtual?: boolean;
}

export function DataTable<T extends Record<string, unknown>>({
  columns,
  data,
  dense,
  expandRows,
  loadMore,
  navbar,
  onRowClick,
  orderColumns,
  resizableColumns,
  sortable,
  total,
  virtual = false,
}: DataTableProps<T>): ReactElement {
  const defaultColumn = useMemo(
    () => ({
      maxWidth: 400,
      minWidth: 30,
      width: 150,
    }),
    []
  );

  const plugins = useMemo(() => {
    const plugins: PluginHook<never>[] = [useBlockLayout, useFilters];

    if (sortable && columns.some((c) => !c.disableSortBy)) {
      plugins.push(useSortBy);
    }

    if (resizableColumns) {
      plugins.push(useResizeColumns);
    }

    if (orderColumns) {
      plugins.push(useColumnOrder);
    }

    return plugins;
  }, [columns, orderColumns, resizableColumns, sortable]);

  const table = useTable<T>(
    {
      // @ts-ignore TODO: improve typings
      columns,
      data: data as T[],
      defaultColumn,
      initialState: {
        // @ts-ignore TODO: ...
        dense: dense || false,
        expandRows: expandRows || false,
        hiddenColumns: columns
          .filter((col) => col.show === false)
          .map((col) => col.id)
          .filter(Boolean),
      },
      stateReducer: (prev, action) => {
        switch (action.type) {
          case "toggleDense":
            // @ts-ignore TODO: ...
            return { ...prev, dense: !prev.dense };
          case "toggleExpandRows":
            // @ts-ignore TODO: ...
            return { ...prev, expandRows: !prev.expandRows };
          default:
            return prev;
        }
      },
    },
    // @ts-ignore TODO: improve typings
    ...plugins
  );

  const count = data.length;
  const Navbar = navbar;

  return (
    <RefreshProvider>
      {Navbar && (
        <div className="d-flex flex-row px-3 justify-content-between align-items-center">
          <Navbar count={count} table={table} total={total} />
        </div>
      )}

      <RenderTableBase table={table}>
        {virtual ? (
          <RenderVirtualTable
            count={count}
            // @ts-ignore TODO: ...
            itemSize={table.state.dense ? 24 : 48}
            loadMore={loadMore}
            table={table}
            total={total}
          />
        ) : (
          <RenderTable
            count={count}
            loadMore={loadMore}
            table={table}
            total={total}
            onRowClick={onRowClick}
          />
        )}
      </RenderTableBase>
    </RefreshProvider>
  );
}
