import { ReactElement, ReactNode } from "react";
import { QueryRenderer as QR } from "react-relay";
import type { GraphQLTaggedNode } from "react-relay";
import type { OperationType } from "relay-runtime";

import { getEnv } from "./ApiEnvProvider";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface Props<TOperation extends OperationType> {
  query: GraphQLTaggedNode | null | undefined;
  render: (renderProps: {
    error: Error | null;
    props: TOperation["response"] | null;
    retry: (() => void) | null;
  }) => ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  variables?: TOperation["variables"];
}

export function QueryRenderer<TOperation extends OperationType>({
  variables = {},
  ...props
}: Props<TOperation>): ReactElement {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  // @ts-ignore TODO: improve typings
  return <QR<TOperation> environment={getEnv()} {...props} variables={variables} />;
}
