import { Button, FormHelperText } from "@material-ui/core";
import { FC, useCallback } from "react";
import { useMutation } from "react-relay/hooks";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";

import { Form, Field } from "components";
import { TextInput } from "components/inputs/TextInput";
import { t } from "helpers";
import { appNavigation } from "helpers";
import { AuthLayout } from "layouts";
import { publicLoginMutation } from "providers/Api";
import { publicLoginMutation as PublicLoginMutation } from "providers/Api/__generated__/publicLoginMutation.graphql";
import { useSetSession } from "providers/AuthProvider";

export const LoginPage: FC = () => {
  const setSession = useSetSession();

  const onCompleted = useCallback(
    (res: PublicLoginMutation["response"]) => {
      if (res.login && res.login.user) {
        setSession(res.login);
      }
    },
    [setSession]
  );

  const [dispatch] = useMutation<PublicLoginMutation>(publicLoginMutation);

  const schema = Yup.object()
    .shape({
      email: Yup.string().nullable().required().email().default(null),
      password: Yup.string().nullable().required().default(null),
    })
    .defined();

  const inits = schema.cast({});

  type FormData = Yup.InferType<typeof schema>;

  const handleLogin = (data: FormData) => {
    dispatch({
      onCompleted,
      onError: (error) => {
        error && toast.error(t("Oops, {msg}", { msg: error.message || error }));
      },
      variables: data,
    });
  };

  return (
    <AuthLayout>
      <Form<FormData>
        className="d-flex flex-column"
        id="loginForm"
        initialValues={inits}
        schema={schema}
        onSubmit={handleLogin}
      >
        <h2 className="mb-5">{t("Sign In")}</h2>

        <FormHelperText className="mb-3">
          {t("Need an Evoneto account?")} <Link to={appNavigation.signUp()}>{t("Sign Up")}</Link>
        </FormHelperText>

        <Field
          fullWidth
          className="mb-3"
          component={TextInput}
          label={t("E-mail Address")}
          name="email"
          variant="standard"
        />

        <Field
          fullWidth
          className="mb-5"
          component={TextInput}
          label={t("Password")}
          name="password"
          type="password"
          variant="standard"
        />

        <Button disableElevation className="mb-3" color="primary" type="submit" variant="contained">
          {t("Sign In")}
        </Button>

        <Link to={appNavigation.forgotPassword()}>{t("Forgot password?")}</Link>
      </Form>
    </AuthLayout>
  );
};
