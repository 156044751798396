import { toast as toastify } from "react-toastify";

// TODO: implement sentry or custom "submit feedback" ??

const message = (type: "error" | "info" | "success" | "warning") => (message: string): void => {
  toastify[type](message, { toastId: message });
};

export const toast = {
  error: message("error"),
  info: message("info"),
  success: message("success"),
  warning: message("warning"),
};
