import cn from "classnames";
import { FC } from "react";

interface Props {
  className?: string;
  lg?: number;
  md?: number;
  sm?: number;
  xs?: number;
}

export const Col: FC<Props> = ({ children, className, lg, md, sm, xs }) => (
  <div
    className={cn("col", className, {
      [`col-xs-${xs}`]: xs,
      [`col-sm-${sm}`]: sm,
      [`col-md-${md}`]: md,
      [`col-lg-${lg}`]: lg,
      "flex-1": !xs && !sm && !md && !lg,
    })}
  >
    {children}
  </div>
);
