import DatePicker, { DatePickerProps } from "@material-ui/lab/DatePicker";
import { Moment } from "moment";
import { FC, ReactNode } from "react";

type Props = DatePickerProps & {
  error?: boolean;
  // TODO: improve typings
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  field: any;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: any;
  format?: string;
  // TODO: improve typings
  helperText?: ReactNode;
  variant?: never;
};

export const DateInput: FC<Props> = ({ error, field, format, helperText, variant, ...props }) => {
  return (
    <DatePicker
      {...props}
      {...field}
      clearable
      fullWidth
      error={!!error}
      format={format || "DD. MM. YYYY"}
      helperText={helperText}
      inputVariant={variant === undefined ? "outlined" : variant}
      size="small"
      value={field.value || null}
      onChange={(v: Moment | undefined | null) => {
        field.onChange({ target: { name: field.name, value: (v && v.toDate()) || null } });
      }}
    />
  );
};
