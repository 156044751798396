import { FC, ReactNode, SyntheticEvent } from "react";

import { cn } from "helpers";
import { styled } from "providers/theme";

export interface ListItemProps {
  active?: boolean;
  className?: string;
  onClick?: (e: SyntheticEvent) => void;
  secondary?: ReactNode;
}

/**
 * List item component
 * TODO add support for:
 * - avatar / icon
 * - actions (on the end of the list)
 */
const Component: FC<ListItemProps> = ({ active, children, className, onClick, secondary }) => {
  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <li
      className={cn(className, { active, button: !!onClick })}
      role={onClick ? "button" : undefined}
      onClick={onClick}
      onKeyDown={devNull}
    >
      <div className="primary" title={typeof children === "string" ? children : undefined}>
        {children}
      </div>
      {secondary && <div className="secondary">{secondary}</div>}
    </li>
  );
};

export const ListItem = styled(Component)`
  padding: 0.5rem 1rem;
  font-size: 1em;
  margin: 0;

  &.active {
    background-color: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.primary.contrastText};
  }

  &.button:hover {
    background-color: ${({ active, theme }) =>
      active ? theme.palette.primary["dark"] : theme.palette.gray["light"]};
  }

  .secondary {
    font-size: 0.75em;
    color: ${({ active, theme }) => theme.palette.gray[active ? "light" : "dark"]};
  }

  .primary {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const devNull = () => undefined;
