/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type userMeQueryVariables = {
    isLogged: boolean;
};
export type userMeQueryResponse = {
    readonly tenant: {
        readonly name: string;
    } | null;
    readonly user?: {
        readonly id: string;
        readonly email: string;
        readonly profile: {
            readonly firstName: string;
            readonly lastName: string;
            readonly nameday: string | null;
            readonly birthday: unknown | null;
            readonly gender: string;
            readonly age: number;
            readonly fullName: string;
            readonly profilePhoto: {
                readonly fullPath: string;
            } | null;
        };
        readonly address: {
            readonly street: string | null;
            readonly city: string | null;
            readonly zip: string | null;
            readonly country: string | null;
        };
        readonly allPermissions: ReadonlyArray<{
            readonly id: string;
            readonly model: string;
            readonly actions: {
                readonly basic: {
                    readonly create: boolean | null;
                    readonly update: boolean | null;
                    readonly read: boolean | null;
                    readonly delete: boolean | null;
                };
                readonly global: {
                    readonly create: boolean | null;
                    readonly update: boolean | null;
                    readonly read: boolean | null;
                    readonly delete: boolean | null;
                };
            };
        }>;
    } | null;
};
export type userMeQuery = {
    readonly response: userMeQueryResponse;
    readonly variables: userMeQueryVariables;
};



/*
query userMeQuery(
  $isLogged: Boolean!
) {
  tenant {
    name
  }
  user: me @include(if: $isLogged) {
    id
    email
    profile {
      firstName
      lastName
      nameday
      birthday
      gender
      age
      fullName
      profilePhoto {
        fullPath
      }
    }
    address {
      street
      city
      zip
      country
    }
    allPermissions {
      id
      model
      actions {
        basic {
          create
          update
          read
          delete
        }
        global {
          create
          update
          read
          delete
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "isLogged"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "create",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "update",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "read",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "delete",
    "storageKey": null
  }
],
v3 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Tenant",
    "kind": "LinkedField",
    "name": "tenant",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "condition": "isLogged",
    "kind": "Condition",
    "passingValue": true,
    "selections": [
      {
        "alias": "user",
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserProfile",
            "kind": "LinkedField",
            "name": "profile",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "firstName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "nameday",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "birthday",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "gender",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "age",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "fullName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "File",
                "kind": "LinkedField",
                "name": "profilePhoto",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "fullPath",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Address",
            "kind": "LinkedField",
            "name": "address",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "street",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "city",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "zip",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "country",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Module",
            "kind": "LinkedField",
            "name": "allPermissions",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "model",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Actions",
                "kind": "LinkedField",
                "name": "actions",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Action",
                    "kind": "LinkedField",
                    "name": "basic",
                    "plural": false,
                    "selections": (v2/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Action",
                    "kind": "LinkedField",
                    "name": "global",
                    "plural": false,
                    "selections": (v2/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "userMeQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "userMeQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "800f9335d0ee75fec03dc0b8a572d9a9",
    "id": null,
    "metadata": {},
    "name": "userMeQuery",
    "operationKind": "query",
    "text": "query userMeQuery(\n  $isLogged: Boolean!\n) {\n  tenant {\n    name\n  }\n  user: me @include(if: $isLogged) {\n    id\n    email\n    profile {\n      firstName\n      lastName\n      nameday\n      birthday\n      gender\n      age\n      fullName\n      profilePhoto {\n        fullPath\n      }\n    }\n    address {\n      street\n      city\n      zip\n      country\n    }\n    allPermissions {\n      id\n      model\n      actions {\n        basic {\n          create\n          update\n          read\n          delete\n        }\n        global {\n          create\n          update\n          read\n          delete\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '09734c236f2c03ea426ab5a9ae8ca361';
export default node;
