import { ReactElement } from "react";
import { TableInstance } from "react-table";

import { useRenderRow } from "./useRenderRow";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface RenderTableProps<T extends Record<string, any>> {
  count?: number;
  loadMore?: (next: number) => void;
  onRowClick?: (row: T, index: number) => void;
  table: TableInstance<T>;
  total?: number;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function RenderTable<T extends Record<string, any>>({
  onRowClick,
  table,
}: RenderTableProps<T>): ReactElement {
  const RenderRow = useRenderRow(table);

  return (
    <div>
      {table.rows
        // .filter((_r, i) => i < 2)
        .map((_row, index) => {
          return (
            <RenderRow
              // @ts-ignore TODO...
              data={table.data[index]}
              index={index}
              key={index}
              // @ts-ignore TODO...
              onClick={onRowClick}
            />
          );
        })}
    </div>
  );
}
