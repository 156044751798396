import cn from "classnames";
import { FC } from "react";
import styled from "styled-components/macro";

import "assets/icomoon/style.css";

interface Props {
  className?: string;
  color?: string;
  name: string;
  size?: number | string;
}

const Component: FC<Props> = ({ className, name }) => {
  return <span arial-label={`Icon ${name}`} className={cn(className, `icon-${name}`)} role="img" />;
};

export const Icon = styled(Component)<Props>`
  color: ${({ color, theme }) => (color ? theme.palette[color] || color : "inherit")};
  font-size: ${({ size }) => (typeof size === "number" ? `${size}px` : size) || "1em"};
`;
