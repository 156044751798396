import { Middleware, RelayNetworkLayerRequest as RelayRequest } from "react-relay-network-modern";

const multipartRequestMiddleware: Middleware = (next) => (req) => {
  // TODO: handle batch
  if ("operation" in req) {
    const {
      id,
      operation: { text: query },
      uploadables,
      variables,
    } = req as RelayRequest & { uploadables?: Record<string, File> };

    if (uploadables) {
      const graphQLMultipart = new FormData();

      const fileMap: { file: File; operationPath: string }[] = [];

      const writeMapFromFileAndMarkVariable = (
        searchable: Record<string, File | Record<string, File> | File[]> | File[],
        parents: string[]
      ) => {
        Object.keys(searchable).forEach((key: string) => {
          // @ts-ignore TODO: polish typings
          const currentValue = searchable[key];

          if (!(currentValue instanceof File)) {
            // Recursive
            writeMapFromFileAndMarkVariable(currentValue, [...parents, key]);
          } else {
            // Consider the non-plain objects inside uplodables as File data.

            fileMap.push({
              file: currentValue,
              operationPath: ["variables", ...parents, key].join("."),
            });

            // Synchronize variable with uploadables.
            let currentDepthVariable = { ...variables };

            parents.forEach((parent: string) => {
              if (!currentDepthVariable[parent]) {
                currentDepthVariable[parent] = {};
              }

              currentDepthVariable = currentDepthVariable[parent];
            });

            currentDepthVariable[key] = null; // Spec: Value of file key should be null.
          }
        });
      };

      writeMapFromFileAndMarkVariable(uploadables, []);

      // eslint-disable-next-line no-unused-vars
      const operations = {
        id,
        query,
        variables,
      };

      graphQLMultipart.append(
        "operations",
        JSON.stringify(operations)
          .replace(/\\n/g, "")
          .replace(/[ ]{2,}/g, " ")
      );

      graphQLMultipart.append(
        "map",
        JSON.stringify(
          fileMap.reduce(
            (reducedMap, value, index) => ({ ...reducedMap, [index]: [value.operationPath] }),
            {}
          )
        )
      );

      fileMap.forEach((mapValue, index) => {
        graphQLMultipart.append(index.toString(), mapValue.file);
      });

      delete req.fetchOpts.headers["Content-Type"];

      req.fetchOpts.body = graphQLMultipart;
    }
  }

  return next(req);
};

export default multipartRequestMiddleware;
