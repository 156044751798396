import {
  FC,
  useMemo,
  useState,
  Dispatch,
  useEffect,
  ReactNode,
  useContext,
  useCallback,
  createContext,
  SetStateAction,
} from "react";
import { useQuery } from "relay-hooks";

import { userMeQuery } from "providers/Api";
import type { publicLoginMutationResponse as PublicLoginMutationResponse } from "providers/Api/__generated__/publicLoginMutation.graphql";
import { userMeQueryResponse } from "providers/Api/__generated__/userMeQuery.graphql";
import { userMeQuery as userMeQueryType } from "providers/Api/__generated__/userMeQuery.graphql";

import { getPersistedState, setPersistedState } from "./persist";

export type SessionState =
  | undefined
  | {
      tenant: NonNullable<userMeQueryResponse["tenant"]>;
      user: NonNullable<userMeQueryResponse["user"]>;
    };
export type SetSession = Dispatch<SetStateAction<SessionState>>;

export const AuthContext = createContext<SessionState>(undefined);
export const AuthSetContext = createContext<SetSession>(() => {
  return undefined;
});

let session: SessionState = undefined;
let set: undefined | SetSession = undefined;

/**
 * Provide auth session
 */
export const AuthProvider: FC<{ fallback?: ReactNode }> = ({ children, fallback }) => {
  const [isLogged, setLogged] = useState(!!getPersistedState()?.wasLoggedIn);

  const { data: ses, isLoading } = useQuery<userMeQueryType>(userMeQuery, { isLogged });

  const fb = useMemo(() => fallback || "Loading", [fallback]);

  const setState = useCallback((v) => {
    const isLogged = Boolean(v && v.user);
    session = v;
    setLogged(isLogged);
    setPersistedState(v);
  }, []);

  useEffect(() => {
    set = setState;

    return () => {
      set = () => {
        // eslint-disable-next-line no-console
        console.log("auth provider was unmounted");
      };
    };
  }, [setState]);

  return (
    <AuthSetContext.Provider value={setState}>
      <AuthContext.Provider value={(ses || undefined) as SessionState | undefined}>
        {isLoading ? fb : children}
      </AuthContext.Provider>
    </AuthSetContext.Provider>
  );
};

/**
 * Enable get session outside JSX
 */
export function getSession(): SessionState {
  return session;
}

/**
 * Enable set session outside JSX
 */
export function setSession(val: SessionState): void {
  if (set) {
    set(val);
  }
}

/**
 * Update session hook
 */
export function useSetSession(): SetSession {
  return useContext(AuthSetContext);
}

/**
 * Session getter hook
 */
export function useSession(): SessionState {
  return useContext(AuthContext);
}

export function useUser(): PublicLoginMutationResponse["login"]["user"] {
  const session = useSession();

  if (!session || !session.user) {
    throw new Error();
  }

  return session.user;
}
