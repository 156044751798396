import graphql from "babel-plugin-relay/macro";

export const publicLoginMutation = graphql`
  mutation publicLoginMutation($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      tenant {
        name
      }
      user {
        id
        email
        # TODO: use USER_PROFILE fragment
        profile {
          firstName
          lastName
          nameday
          birthday
          gender
          age
          fullName
          gender
          profilePhoto {
            fullPath
          }
        }
        address {
          street
          city
          zip
          country
        }
        allPermissions {
          id
          model
          actions {
            basic {
              create
              update
              read
              delete
            }
            global {
              create
              update
              read
              delete
            }
          }
        }
      }
    }
  }
`;

export const publicForgotMutation = graphql`
  mutation publicForgotPasswordMutation($email: String!) {
    forgotPassword(email: $email)
  }
`;

export const publicResetPasswordMutation = graphql`
  mutation publicResetPasswordMutation($reset: ResetPasswordInput!) {
    resetPassword(resetPassword: $reset) {
      tenant {
        name
      }
      user {
        id
        email
        # TODO: use USER_PROFILE fragment
        profile {
          firstName
          lastName
          nameday
          birthday
          gender
          age
          fullName
          gender
          profilePhoto {
            fullPath
          }
        }
        address {
          street
          city
          zip
          country
        }
        allPermissions {
          id
          model
          actions {
            basic {
              create
              update
              read
              delete
            }
            global {
              create
              update
              read
              delete
            }
          }
        }
      }
    }
  }
`;
