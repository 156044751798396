import { createContext, memo, FC, useContext, useMemo } from "react";
import { AutoSizer } from "react-virtualized";

const Context = createContext({ height: 1, width: 1 });

/** Provide auto-size box size via context */
export const AutoSizerProvider: FC = memo(({ children }) => {
  return (
    <AutoSizer>
      {({ height, width }) => (
        <Context.Provider value={{ height, width }}>{children}</Context.Provider>
      )}
    </AutoSizer>
  );
});

/** Consume nearest auto-size parent */
export function useAutoSize(): { height: number; width: number } {
  const { height, width } = useContext(Context);

  return useMemo(() => ({ height, width }), [height, width]);
}
