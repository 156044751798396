import IntlMessageFormat from "intl-messageformat";
import type { FormatXMLElementFn } from "intl-messageformat";
import type { MessageFormatElement } from "intl-messageformat-parser";

type Values<T = void> =
  | Record<
      string,
      | string
      | number
      | boolean
      | Date
      | T
      | FormatXMLElementFn<T, string | T | (string | T)[]>
      | null
      | Error
      | undefined
    >
  | undefined;

export function t(message: string | MessageFormatElement[], values?: Values<string>): string {
  const msg = new IntlMessageFormat(message, getLocale());

  // @ts-ignore TODO: serialize Error ?
  const res = msg.format<string>(values);

  return Array.isArray(res) ? res.join("") : res;
}

export function getLocale(): string {
  return "en";
}
