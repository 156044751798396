import Button from "@material-ui/core/Button";
import styled from "styled-components/macro";

export const Btn = styled(Button)`
  display: flex;
  max-height: none;
  max-width: 100%;
  box-sizing: border-box;
  margin: -0.5rem;

  .MuiButton-label {
    display: flex;
    flex-direction: row;
    padding: 0;

    div + div {
      line-height: 1.5;
      flex: 1;
      overflow: hidden;
      width: auto;
      padding-right: 0.5rem;

      & > * {
        display: block;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
`;
