import { FC } from "react";

import { appNavigation } from "helpers";
import { ListingPageLayout } from "layouts/ListingPageLayout";
import type { contacts_pagination } from "providers/Api/__generated__/contacts_pagination.graphql";
import { contactsPaginationQuery, contactsPaginationFragment } from "providers/ApiProvider";

type Contact = contacts_pagination["list"]["edges"][0]["node"];

const columns = [
  {
    Header: "Email",
    accessor: (r: Contact) => <a href={appNavigation.clientDetail(r.id)}>{r.mail}</a>,
  },
  {
    Header: "Position",
    accessor: "position",
  },
  {
    Header: "Phone",
    accessor: "phone",
  },
];

export const ContactListPage: FC = () => {
  return (
    <ListingPageLayout
      columns={columns}
      fragment={contactsPaginationFragment}
      query={contactsPaginationQuery}
    />
  );
};
