/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type userLogoutMutationVariables = {};
export type userLogoutMutationResponse = {
    readonly logout: boolean;
};
export type userLogoutMutation = {
    readonly response: userLogoutMutationResponse;
    readonly variables: userLogoutMutationVariables;
};



/*
mutation userLogoutMutation {
  logout
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "logout",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "userLogoutMutation",
    "selections": (v0/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "userLogoutMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "75b86a3c06f25016076bad1f853c5ab6",
    "id": null,
    "metadata": {},
    "name": "userLogoutMutation",
    "operationKind": "mutation",
    "text": "mutation userLogoutMutation {\n  logout\n}\n"
  }
};
})();
(node as any).hash = '524e8e5129394b02da9e4ce28318a54c';
export default node;
