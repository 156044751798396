import {
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog as UIDialog,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { FC, ReactNode } from "react";

import { t } from "helpers";

interface Props {
  actions?: false | ReactNode[];
  classes?: Record<string, string>;
  isOpen?: boolean;
  maxWidth?: false | "xs" | "sm" | "md" | "lg" | "xl" | undefined;
  onRequestClose?: () => void;
  onRequestSubmit?: () => void;
  title: ReactNode;
}

export const Dialog: FC<Props> = ({
  actions,
  children,
  classes,
  isOpen,
  maxWidth = "sm",
  onRequestClose,
  onRequestSubmit,
  title,
}) => {
  const actionFooter =
    actions === false
      ? undefined
      : actions || [
          <Button key="cancel" variant="contained" onClick={onRequestClose}>
            {t("Cancel")}
          </Button>,
          <Button color="primary" key="submit" variant="contained" onClick={onRequestSubmit}>
            {t("Ok")}
          </Button>,
        ];
  return (
    <UIDialog
      aria-labelledby="customized-dialog-title"
      maxWidth={maxWidth}
      open={!!isOpen}
      onClose={onRequestClose}
    >
      {/* @ts-ignore TODO: fix */}
      {title && <DialogTitle onClose={() => onClose && onClose()}>{title}</DialogTitle>}
      <DialogContent dividers className={(classes || {}).content}>
        {children}
      </DialogContent>
      {actionFooter && <DialogActions>{actionFooter}</DialogActions>}
    </UIDialog>
  );
};

export const LeftAction: FC = ({ children }) => {
  const classes = leftClasses();

  return <div className={classes.root}>{children}</div>;
};

const leftClasses = makeStyles(() => ({
  root: { marginRight: "auto" },
}));

export default Dialog;
