import { css } from "providers";

export const styles = css`
  position: relative;
  &:before {
    background-color: ${({ theme }) => theme.palette.light.dark};
    position: absolute;
    content: "";
    width: 100%;
    height: 1px;
    z-index: 0;
    bottom: 0;
  }
`;
