import { ReactNode, useReducer, ReactElement, useCallback } from "react";
import * as Yup from "yup";

import { initialState, FormStateContext, FormActionContext } from "./FormContext";
import type { Data } from "./FormContext";
import { formReducer } from "./formReducer";
import type { FormState, FormAction } from "./formReducer";
import { HiddenSubmit } from "./HiddenSubmit.styled";

export interface FormProps<D extends Data> {
  children?: ReactNode;
  className?: string;
  id: string;
  initialValues?: D;
  onSubmit?: (data: D) => void;
  reducer?: (s: FormState<D>, a: FormAction<D>) => FormState<D>;
  /** Validation schema = one time initialization = use custom reducer to change schema */
  schema?: Yup.SchemaOf<D>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function Form<D extends Data = any>({
  children,
  className,
  id,
  initialValues,
  onSubmit,
  schema,
  ...props
}: FormProps<D>): ReactElement {
  type R = (s: FormState<D>, a: FormAction<D>) => FormState<D>;
  // @ts-ignore TODO: inspect this infinity ...
  const reducer: R = props.reducer || formReducer;
  // TODO: make it simpler
  const [state, dispatch] = useReducer<R>(reducer, {
    ...initialState,
    schema,
    values: {
      ...(schema ? schema.cast(initialValues || {}) : initialValues || {}),
    } as D,
  });

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();

      dispatch({ cb: (d) => onSubmit && onSubmit(d), type: "onSubmit" });
    },
    [onSubmit]
  );

  return (
    <FormActionContext.Provider value={dispatch}>
      <FormStateContext.Provider value={state}>
        <form className={className} id={id} onSubmit={handleSubmit}>
          {children}
          <HiddenSubmit />
        </form>
      </FormStateContext.Provider>
    </FormActionContext.Provider>
  );
}
