import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import Popover from "@material-ui/core/Popover";
import { FC, memo, useState } from "react";

import { Icon } from "components";
import { appNavigation, getHistory, t } from "helpers";
import { userAvatar } from "helpers";
import { useSession } from "providers/AuthProvider";

import { Btn } from "./Btn";

export const UserDropdown: FC = memo(() => {
  const [element, setElement] = useState<HTMLButtonElement>();
  const session = useSession();

  return (
    <>
      <Btn
        fullWidth
        className="justify-content-start align-items-center"
        size="large"
        variant="text"
        onClick={(event) => {
          setElement(event.currentTarget);
        }}
      >
        <Avatar className="mr-2" role="img" sizes="small" src={userAvatar(session?.user)} />
        <div className="text-left d-flex flex-1 flex-column">
          {/* TODO: use fullName */}
          <span style={{ lineHeight: 1 }}>
            {`${session?.user.profile.fullName}`.trim() || session?.user.email}
          </span>
          <small>{session?.user.email}</small>
        </div>
        <Icon name="chevron-down" size="0.5rem" />
      </Btn>

      <Popover
        anchorEl={element}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
        id="user-menu"
        open={!!element}
        transformOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
        onClose={() => setElement(undefined)}
      >
        <List dense component="nav">
          <ListItem
            button
            onClick={() => {
              getHistory().push(appNavigation.myProfile());
              setElement(undefined);
            }}
          >
            <ListItemAvatar>
              <Icon name="user" />
            </ListItemAvatar>
            <ListItemText primary={t("My Profile")} />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              getHistory().push(appNavigation.logout());
              setElement(undefined);
            }}
          >
            <ListItemAvatar>
              <Icon name="logout" />
            </ListItemAvatar>
            <ListItemText primary={t("Logout")} />
          </ListItem>
        </List>
      </Popover>
    </>
  );
});
