import { FC, ComponentType, CSSProperties, SyntheticEvent } from "react";
import { TableInstance } from "react-table";

import { cn, t } from "helpers";

import { Td, Tr } from ".";

export interface ListChildComponentProps<T extends Record<string, unknown>> {
  data: T;
  index: number;
  isScrolling?: boolean;
  onClick?: (row: T, index: number) => void;
  style?: CSSProperties;
}

// TODO: optimize params - all table looks dangerous
export function useRenderRow<T extends Record<string, unknown>>(
  table: TableInstance<T>
): ComponentType<ListChildComponentProps<T>> {
  const RenderRow: FC<ListChildComponentProps<T>> = ({ data, index, onClick, style }) => {
    const { prepareRow, rows, state } = table;
    const row = rows[index];

    row && prepareRow(row);

    const rowProps = row
      ? {
          ...row.getRowProps({
            style,
          }),
        }
      : { style };

    return (
      <>
        <Tr
          {...rowProps}
          className={cn(index % 2 === 0 ? "even" : "odd", {
            // @ts-ignore TODO: ...
            dense: state.dense,
            // @ts-ignore TODO: ...
            "expand-row": state.expandRows,
          })}
          onClick={(e: SyntheticEvent) => {
            if (!onClick) {
              return;
            }
            e.preventDefault();
            e.stopPropagation();
            onClick && onClick((data as unknown) as T, index);
          }}
        >
          {row ? (
            row.cells.map((cell) => {
              return (
                <Td
                  // @ts-ignore TODO: fix typings
                  className={cn({ expand: cell.column.expand })}
                  {...cell.getCellProps()}
                >
                  {cell.render("Cell")}
                </Td>
              );
            })
          ) : (
            <div>{t("Loading...")}</div>
          )}
        </Tr>
      </>
    );
  };

  return RenderRow;
}
