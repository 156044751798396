import { TypedDocumentNode, useQuery } from "@apollo/client";
import { FC, useCallback, useState } from "react";

import { DataTable } from "components/DataTable";
import { SearchBar } from "components/SearchBar";

interface Props {
  // Pagination query
  columns: unknown[];
  query: TypedDocumentNode;
}

interface Filters {
  [key: string]: unknown;
  search: null | string; // TODO
}

export const ListingPageLayout2: FC<Props> = ({ columns, query }) => {
  const [filters, setFilters] = useState<Filters>({ search: null });

  const handleSearch = useCallback((v) => setFilters((s) => ({ ...s, search: v })), []);

  const q = useQuery<{
    list: { edges: { id: string; node: { id: string } }[]; totalCount: number };
  }>(query);

  const { list } = q.data || {};
  const items = (list?.edges || []).map((e) => e.node) || [];

  const handleLoadMore = useCallback((next: number) => {
    return new Promise<void>((_res) => {
      // load more
      // eslint-disable-next-line no-console
      console.log("load next", next);
      return [];
    });
  }, []);

  return (
    <div className="d-flex flex-column">
      <SearchBar value={filters.search} onChange={handleSearch} />
      <DataTable
        // @ts-ignore TODO: fix typing
        columns={columns}
        data={items}
        isLoading={q.loading}
        loadMore={handleLoadMore}
        total={list?.totalCount || 0}
      />
    </div>
  );
};
