import cn from "classnames";
import { FC } from "react";

import type { IconName } from "assets/icomoon/IconName";
import { AppTheme, styled } from "providers/theme";

import "assets/icomoon/style.css";

export interface IconProps {
  className?: string;
  color?: keyof AppTheme["palette"];
  name: IconName;
  size?: number | string;
}

const Component: FC<IconProps> = ({ className, name }) => {
  return <span arial-label={`Icon ${name}`} className={cn(className, `icon-${name}`)} role="img" />;
};

export const Icon = styled(Component)<IconProps>`
  color: ${({ color, theme }) =>
    color ? (theme.palette[color] ? theme.palette[color].main : color) : "inherit"};
  font-size: ${({ size }) => (typeof size === "number" ? `${size}px` : size) || "0.85em"};
  line-height: ${({ size }) => (typeof size === "number" ? `${size}px` : size) || "0.85em"};
  display: inline-block;
  text-align: center;
  position: relative;
`;
