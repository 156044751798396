import graphql from "babel-plugin-relay/macro";

export const clientDetailQuery = graphql`
  query clientDetailQuery($id: String!) {
    client(id: $id) {
      id
      name
      type
      ico: bussinessId
      taxId
      vat
      note
      discount
      address {
        id
        street
        zip
        city
        country
      }
    }
  }
`;

export const clientPaginationQuery = graphql`
  query clientPaginationQuery($first: Int, $after: String, $search: String) {
    ...client_pagination @arguments(search: $search, first: $first, after: $after)
  }
`;

export const clientPaginationFragment = graphql`
  fragment client_pagination on Query
  @argumentDefinitions(
    search: { type: "String" }
    first: { type: "Int", defaultValue: 10 }
    after: { type: "String" }
  ) {
    list: clients(first: $first, after: $after, filter: { search: $search })
      @connection(key: "Client_list") {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          name
          bussinessId
        }
      }
    }
  }
`;

export const clientUpdateMutation = graphql`
  mutation clientUpdateMutation($id: String!, $client: ClientInput!) {
    updateClient(clientUuid: $id, client: $client) {
      id
      name
      type
      ico: bussinessId
      taxId
      vat
      note
      discount
      address {
        id
        street
        zip
        city
        country
      }
    }
  }
`;
