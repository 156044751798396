import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { FC, ReactNode } from "react";

export interface SettingsListProps {
  active?: string;
  items: { id: string; primary: ReactNode; secondary?: ReactNode }[];
  onSelectItem?: (val: string) => void;
}

export const NavList: FC<SettingsListProps> = ({ active, items, onSelectItem }) => {
  return (
    <List component="nav">
      {items.map((item) => (
        <ListItem
          button
          dense
          key={item.id}
          selected={!!(active === item.primary)}
          onClick={() => onSelectItem && onSelectItem(item.id)}
        >
          <ListItemText color="primary" primary={item.primary} secondary={item.secondary} />
        </ListItem>
      ))}
    </List>
  );
};
