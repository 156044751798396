import { FC, Children, cloneElement, ReactElement } from "react";

import { styled } from "providers/theme";

import { Button } from "./Button";
import type { ButtonProps } from "./Button";
import { buttonRadiusMixin, buttonSizeMixin } from "./Button.styled";
import { IconButton } from "./IconButton";

export interface ButtonGroupProps {
  className?: string;
  color?: ButtonProps["color"];
  rounded?: boolean;
  size?: ButtonProps["size"];
  variant?: ButtonProps["variant"];
}

const Component: FC<ButtonGroupProps> = ({ children, className, ...props }) => {
  const a = Children.toArray(children);
  const a2 = a.map((e) => cloneElement(e as ReactElement, { ...props }));
  return <div className={className}>{a2}</div>;
};

/**
 * TODO: implement more features, currently its used only for sizing
 */
export const ButtonGroup = styled(Component).attrs<ButtonGroupProps>((p) => ({
  ...p,
  size: p.size || "normal",
}))<ButtonGroupProps>`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  position: relative;

  & > ${/* sc-selector */ IconButton}, & > ${Button} {
    ${buttonSizeMixin}
    box-shadow: none;
  }

  & > ${Button} {
    &:not(${/* sc-selector */ IconButton}) {
      border-radius: 0;

      &:first-of-type {
        ${buttonRadiusMixin}
      }
      &:last-of-type {
        ${buttonRadiusMixin}
      }

      &:not(:first-of-type) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      &:not(:last-of-type) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
`;
