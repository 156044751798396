import { Button } from "@material-ui/core";
import { Tooltip } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import { makeStyles } from "@material-ui/styles";
import { FC, ReactNode, useRef, useState } from "react";

import { t } from "helpers";

import Dialog, { LeftAction } from "../Dialog";
import { Dropzone } from "../Dropzone";

import { AvatarEditor } from "./AvatarEditor";

interface Props {
  isOpen?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (value: any) => void;
  onRequestClose?: () => void;
  title: ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any;
}

export const AvatarDialog: FC<Props> = ({
  isOpen,
  onChange,
  onRequestClose,
  title,
  value = null,
}) => {
  const [file, setFile] = useState<File | File[] | null>(value);
  const confirmRef = useRef<() => Promise<File>>();
  const classes = useStyles();

  return (
    <Dialog
      actions={[
        <LeftAction key="delete">
          <Button disabled={!file} key="delete" onClick={() => setFile(null)}>
            {t("Vyber inú")}
          </Button>
        </LeftAction>,
        <Tooltip
          key="tooltip"
          title={t(
            "V prípade že nevidíte požadovanú plochu na orezanie, a nedá sa odzoomovať, použite obrázok s iným pomerom strán, ideálne 1:1"
          )}
        >
          <InfoIcon />
        </Tooltip>,
        <Button key="cancel" onClick={onRequestClose}>
          {t("Zrušiť")}
        </Button>,
        <Button
          key="save"
          onClick={() => {
            const res = confirmRef.current && confirmRef.current();
            if (res) {
              res.then((value) => {
                onChange && onChange(value);
                onRequestClose && onRequestClose();
              });
            } else {
              onRequestClose && onRequestClose();
            }
          }}
        >
          {t("Použiť")}
        </Button>,
      ]}
      classes={{ content: classes.content }}
      isOpen={!!isOpen}
      title={title || t("Select Avatar")}
      onRequestClose={onRequestClose}
    >
      {file ? (
        <>
          <AvatarEditor confirmRef={confirmRef} value={file} />
        </>
      ) : (
        <div className={classes.dropSpace}>
          <Dropzone
            accept={[".jpg", ".jpeg", ".png"]}
            height={280}
            width={280}
            onChange={setFile}
          />
        </div>
      )}
    </Dialog>
  );
};

const useStyles = makeStyles(() => ({
  content: {
    padding: 0,
  },
  dropSpace: {
    padding: 20,
  },
}));
