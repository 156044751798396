import { appNavigation, customRoutes } from "./config";

type NavKeys = keyof typeof appNavigation;

/**
 * Compute app routes for react-router
 */
// @ts-ignore TODO: improve typings
export const appRoutes: { [key in NavKeys]: string } = {
  ...customRoutes,
  // go over all app nav-links
  ...Object.keys(appNavigation).reduce(
    (acc, k) => {
      // if route is defined
      if (customRoutes.hasOwnProperty(k)) {
        // skip creating fallback
        return acc;
      }
      // otherwise try to create generic fallback

      // if resolver requires any arguments
      if (appNavigation[k as NavKeys].length) {
        throw new Error(
          // if this will be found on prod, u will be fired
          "DEV ERROR: unexpected route fallback, you have to define custom route for items which are expecting arguments"
        );
      }

      // create fallback from resolver = static routes
      // @ts-ignore TODO: inspect how to say TS to use prev filter
      return { ...acc, [k]: appNavigation[k as NavKeys]() as string };
    }, // use fallback otherwise
    {}
  ),
};

export { appNavigation };

// protect global against changes

Object.freeze(appNavigation);

Object.freeze(appRoutes);
