import graphql from "babel-plugin-relay/macro";

export const projectListQuery = graphql`
  query projectListQuery($first: Int!, $after: String) {
    projects(first: $first, after: $after) {
      # @connection(key: "Project_projects")
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          name
          code
          description
          client {
            id
            name
            logo {
              fullPath
            }
          }
        }
      }
    }
  }
`;
