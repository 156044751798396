import graphql from "babel-plugin-relay/macro";

export const userQuery = graphql`
  query userQuery($id: String!) {
    user(id: $id) {
      __typename
      id
      email
      # TODO: use USER_PROFILE fragment
      profile {
        firstName
        middleName
        lastName
        nameday
        birthday
        gender
        age
        fullName
        gender
        profilePhoto {
          fullPath
        }
      }
      workData {
        position
      }
      address {
        street
        city
        zip
        country
      }
      allPermissions {
        id
        model
        actions {
          basic {
            create
            update
            read
            delete
          }
          global {
            create
            update
            read
            delete
          }
        }
      }
    }
  }
`;

export const userMeQuery = graphql`
  query userMeQuery($isLogged: Boolean!) {
    tenant {
      name
    }
    user: me @include(if: $isLogged) {
      id
      email
      # TODO: use USER_PROFILE fragment
      profile {
        firstName
        lastName
        nameday
        birthday
        gender
        age
        fullName
        gender
        profilePhoto {
          fullPath
        }
      }
      address {
        street
        city
        zip
        country
      }
      allPermissions {
        id
        model
        actions {
          basic {
            create
            update
            read
            delete
          }
          global {
            create
            update
            read
            delete
          }
        }
      }
    }
  }
`;

export const userMutation = graphql`
  mutation userUpdateMutation($user: UserInput!) {
    updateUser(user: $user) {
      __typename
      id
      # TODO: use USER_PROFILE fragment
      profile {
        fullName
        firstName
        middleName
        lastName
        nameday
        birthday
        gender
        age
        profilePhoto {
          fullPath
        }
      }
      workData {
        position
      }
      address {
        street
        city
        zip
        country
      }
    }
  }
`;

export const userInviteMutation = graphql`
  mutation userInviteMutation($email: String!, $user: UserInput!) {
    inviteUser(email: $email, user: $user)
  }
`;

export const userLogoutMutation = graphql`
  mutation userLogoutMutation {
    logout
  }
`;

export const userPaginationQuery = graphql`
  query userPaginationQuery($first: Int, $after: String, $search: String) {
    ...users_pagination @arguments(search: $search, first: $first, after: $after)
  }
`;

export const userPaginationFragment = graphql`
  fragment users_pagination on Query
  @argumentDefinitions(
    search: { type: "String" }
    first: { type: "Int", defaultValue: 10 }
    after: { type: "String" }
  )
  @refetchable(queryName: "usersRefetchQuery") {
    list: users(first: $first, after: $after, filter: { search: $search })
      @connection(key: "User_list") {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          workData {
            position
            cardId
          }
          profile {
            title
            fullName
            profilePhoto {
              fullPath
            }
          }
        }
      }
    }
  }
`;
