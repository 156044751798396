import { FC, useCallback, useMemo, useState } from "react";

import { LoadingIndicator } from "components";
import type { projectListQuery as projectListQueryType } from "providers/Api/__generated__/projectListQuery.graphql";
import { projectListQuery } from "providers/Api/project";
import { QueryRenderer } from "providers/ApiProvider";

import { ProjectsList } from "./ProjectsList";

export const ProjectsListPage: FC = () => {
  const [search, setSearch] = useState<string>();

  const handleSearch = useCallback((s?: string) => {
    setSearch(s && s.length > 2 ? s : undefined);
  }, []);

  const variables = useMemo(() => ({ first: 10, queries: { search } }), [search]);

  const render = useCallback((args) => renderQuery({ ...args, handleSearch, search }), [
    search,
    handleSearch,
  ]);

  return (
    <QueryRenderer<projectListQueryType>
      query={projectListQuery}
      render={render}
      variables={variables}
    />
  );
};

const renderQuery = ({
  error,
  handleSearch,
  props,
  search,
}: {
  error?: Error;
  handleSearch: (search?: string) => void;
  props: projectListQueryType["response"];
  search?: string;
}) => {
  if (error) {
    // TODO: err page
    return <div>{error.message}</div>;
  } else if (props && props.projects) {
    return <ProjectsList projects={props.projects} search={search} onSearch={handleSearch} />;
  }

  return <LoadingIndicator />;
};
