import { createTheme } from "@material-ui/core";
import { lightBlue } from "@material-ui/core/colors";
import { lighten, darken, readableColor } from "polished";
import baseStyled, {
  css as csss,
  ThemedCssFunction,
  ThemedStyledInterface,
  keyframes as styledKeyframes,
} from "styled-components/macro";

const dark = "#333333";
const light = "#ffff";

const getColorVariants = (color: string, contrast?: string) => ({
  /** Contrast text color - for best read experience */
  contrastText: contrast || readableColor(color, light, dark),
  /** Darken version for e.g. "hover" effect  */
  dark: darken(0.1, color),
  // here should be used "light.main" as text color
  /** Lighter version for e.g. "active" or "focused" effect  */
  light: lighten(0.5, color),
  // here should be used "dark.main" as text color
  main: color,
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getTheme = () => {
  const palette: Record<string, ReturnType<typeof getColorVariants>> = {
    danger: getColorVariants("#FF423F", light),
    dark: {
      contrastText: "#ffffff",
      dark: "#333333",
      light: "#333333",
      main: "#333333",
    },
    gray: {
      contrastText: dark,
      dark: "#ACACAC",
      light: "#EEEEEE",
      main: "#d8d8d8",
    },
    info: getColorVariants("#44BDFF", light),
    light: {
      contrastText: "#ffffff",
      dark: "#f9f9f9",
      light: "#ffffff",
      main: "#fefefe",
    },
    link: getColorVariants(lightBlue["A100"], light),
    primary: getColorVariants(lightBlue["A100"], light),
    secondary: {
      contrastText: "#ffffff",
      dark: "#7a6b74",
      light: "#7a6b74",
      main: "#7a6b74",
    },
    success: getColorVariants("#7CBF11", light),
    warning: getColorVariants("#FFAE23", light),
  };

  const theme = {
    bodyBg: "#fefefe",
    headingColor: "#000000",
    palette: {
      ...palette,
    },
    radius: {
      large: "16px",
      medium: "8px",
      normal: "4px",
      tiny: "2px",
    },
    shadow: {
      button: "0 0 0.3rem rgba(0, 0, 0, 0.3)" /* black with 30% opacity */,
      nvbar: "0 0.5rem 0.5rem rgba(0, 0, 0, 0.1)" /* black with 10% opacity */,
      thead: "0 0.25rem 0.25rem rgba(0, 0, 0, 0.05)",
    },
    shape: {
      borderRadius: 2,
    },
    textColor: "#575757",
    typography: {
      button: { fontWeight: 300, lineHeight: 2.2, textTransform: "none" },
    },
  } as const;

  const mui = createTheme(theme);
  return {
    ...theme,
    ...mui,
    overrides: {
      MuiFormLabel: { root: { fontWeight: 300 } },
      MuiInputBase: { input: { fontWeight: 300 } },
      MuiListItemAvatar: { root: { minWidth: "32px" } },
      MuiOutlinedInput: {
        adornedEnd: { paddingRight: 0 },
        adornedStart: { paddingLeft: 0 },
      },
      MuiTab: { root: { minWidth: "auto !important", padding: "0 2rem" } },
      MuiTabs: { scrollButtons: { "&.Mui-disabled": { opacity: 0.25 } } },
      PrivateTabIndicator: {
        root: { height: "1px" },
      },
    },
    palette: ({
      ...mui.palette,
      ...theme.palette,
    } as unknown) as typeof theme.palette,
    textColor: "#575757",
  };
};

export type AppTheme = ReturnType<typeof getTheme>;

export const keyframes = styledKeyframes;

export const css: ThemedCssFunction<AppTheme> = csss;
export const styled = baseStyled as ThemedStyledInterface<AppTheme>;
