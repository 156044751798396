import { t } from "./translate";

enum ClientType {
  CLIENT = "CLIENT",
  CONTRACTOR = "CONTRACTOR",
  EXTERNAL = "EXTERNAL",
  INTERNAL = "INTERNAL",
  OLD_CLIENT = "OLD_CLIENT",
  OTHER = "OTHER",
  POTENTIAL_CLIENT = "POTENTIAL_CLIENT",
}

export function translateClientType(type: string): string {
  const map: Record<ClientType, string> = {
    CLIENT: t("Client"),
    CONTRACTOR: t("Contractor"),
    EXTERNAL: t("Extern"),
    INTERNAL: t("Intern"),
    OLD_CLIENT: t("Old"),
    OTHER: t("Other"),
    POTENTIAL_CLIENT: t("Potential"),
  };

  // @ts-ignore TODO: force using client type
  return type in map ? map[type] : map.OTHER;
}
