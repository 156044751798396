import { Button, FormHelperText } from "@material-ui/core";
import { FC, useCallback } from "react";
import { useMutation } from "react-relay/hooks";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";

import { Icon } from "components";
import { Form, Field } from "components";
import { TextInput } from "components/inputs/TextInput";
import { appNavigation, t, getHistory } from "helpers";
import { AuthLayout } from "layouts";
import { publicForgotMutation } from "providers/Api";
import type { publicForgotPasswordMutation } from "providers/Api/__generated__/publicForgotPasswordMutation.graphql";

export const ForgotPasswordPage: FC = () => {
  const onCompleted = useCallback((res: publicForgotPasswordMutation["response"]) => {
    if (res.forgotPassword) {
      toast.success(t("Please check your email for instructions."));
      getHistory().push(appNavigation.login());
    } else if (res && res.forgotPassword === false) {
      toast.warning(t("We were not able to sent you an email."));
    }
  }, []);

  const [dispatch] = useMutation<publicForgotPasswordMutation>(publicForgotMutation);

  const schema = Yup.object()
    .shape({
      email: Yup.string().nullable().required().email().default(null),
    })
    .defined();

  const inits = schema.cast({});

  type FormData = Yup.InferType<typeof schema>;

  const handleForgot = (variables: FormData) => {
    dispatch({
      onCompleted,
      onError: (error) => {
        error && toast.error(t("Oops, {msg}", { msg: error.message || error }));
      },
      variables,
    });
  };

  return (
    <AuthLayout>
      <h2 className="mb-5">{t("Forgot password?")}</h2>

      <FormHelperText className="mb-3">
        {t("Enter your email and we send you instructions how to get access to your account.")}
      </FormHelperText>

      <Form id="forgotPasswordForm" initialValues={inits} schema={schema} onSubmit={handleForgot}>
        <Field
          fullWidth
          className="mb-3"
          component={TextInput}
          label={t("E-mail Address")}
          name="email"
          variant="standard"
        />

        <Button fullWidth className="mb-3" color="primary" type="submit" variant="contained">
          {t("Send")}
        </Button>
      </Form>

      <FormHelperText className="mb-3">
        {t("Do you have problem to get email ?")}
        <br />
        {/* TODO: add support action */}
        {t("Feel free to")} <Link to="#contact">{t("Contact support")}</Link>
      </FormHelperText>

      <Link to={appNavigation.login()}>
        <Icon name="arrow-left" /> {t("Back to login")}
      </Link>
    </AuthLayout>
  );
};
