import { gql, useQuery } from "@apollo/client";
import { Typography } from "@material-ui/core";
import { FC } from "react";
import { useMemo } from "react";

import { SidePanel } from "components/SidePanel";
import { setQueryParam, useQueryParam } from "helpers";
import { t } from "helpers";

import { NavList } from "./NavList";

const PARAM_NAME = "setting-category";

export const MyCompanyPage: FC = () => {
  const companySettings = useQuery<{ settings: { description: string; label: string }[] }>(
    settingsQuery
  );

  // eslint-disable-next-line no-console

  const settings = useMemo(
    () =>
      (companySettings.data?.settings || []).map((category) => ({
        id: category.label, // TODO: use ID and translated label
        primary: category.label,
        secondary: category.description,
      })),
    [companySettings.data]
  );

  const handleSet = (value: string) => setQueryParam(PARAM_NAME, value);

  const active = useQueryParam(PARAM_NAME, "company-details");

  return (
    <div className="d-flex flex-row w-100 h-100">
      <SidePanel>
        <NavList
          active={active}
          items={[{ id: "company-details", primary: t("Company details") }]}
          onSelectItem={handleSet}
        />
        <Typography color="secondary" variant="caption">
          {t("Settings")}
        </Typography>
        <NavList active={active} items={settings} onSelectItem={handleSet} />
      </SidePanel>
      <div>main</div>
    </div>
  );
};

const settingsQuery = gql`
  query settingsList {
    settings: tenantSettingsConfig {
      label
      description
      data {
        label
        enabled
        enabledText
        inputs {
          label
          name
          type
          enabled
        }
      }
    }
  }
`;
