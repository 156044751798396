import grey from "@material-ui/core/colors/grey";
import Slider from "@material-ui/core/Slider";
import ImageIcon from "@material-ui/icons/Image";
import { makeStyles } from "@material-ui/styles";
import { ElementRef, FC, useState, useRef } from "react";
import OriginalAvatarEditor from "react-avatar-editor";

interface Props {
  border?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  confirmRef?: ElementRef<any>;
  radius?: number;
  size?: number;
  value: File | File[] | null;
}

export const AvatarEditor: FC<Props> = ({
  border = 20,
  confirmRef,
  radius = 20,
  size = 280,
  value,
}) => {
  const [scale, setScale] = useState(120);
  // @ts-ignore TODO: fix
  const classes = useStyles({ border, size });
  const editor = useRef<typeof OriginalAvatarEditor>();

  const onClickSave = () => {
    if (editor.current) {
      return new Promise((finish) => {
        // @ts-ignore TODO: fix  problem
        const canvas = editor?.current?.getImageScaledToCanvas().toDataURL();
        fetch(canvas)
          .then((res) => res.blob())
          .then((blob) => {
            const f = new File([blob], "image.png", { type: "image/png" });
            finish(f);
          });
      });
    }
  };

  if (confirmRef) {
    // @ts-ignore TODO: fix  problem
    confirmRef.current = onClickSave;
  }

  return (
    <div className={classes.root}>
      <div className={classes.imageWrapper}>
        {/* @ts-ignore TODO: fix  problem */}
        <OriginalAvatarEditor
          ref={editor}
          border={border}
          borderRadius={radius}
          height={size}
          image={value}
          scale={scale / 100}
          width={size}
        />
      </div>
      <div className={classes.sliderWrapper}>
        <ImageIcon className={classes.icon} fontSize="small" />
        <Slider
          aria-label="custom thumb label"
          className={classes.slider}
          defaultValue={120}
          marks={[{ value: 100 }]}
          max={250}
          min={50}
          step={10}
          onChange={(e, val) => setScale(Number(val))}
        />
        <ImageIcon className={classes.icon} fontSize="large" />
      </div>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  icon: {
    color: grey.A200,
  },
  imageWrapper: {
    backgroundColor: "#666666",
    backgroundImage:
      "linear-gradient(45deg, #777777 25%, transparent 25%), linear-gradient(-45deg, #777777 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #777777 75%), linear-gradient(-45deg, transparent 75%, #777777 75%)",
    backgroundPosition: "0 0, 0 10px, 10px -10px, -10px 0px",
    backgroundSize: "20px 20px",
    display: "flex",
  },

  root: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    margin: "0 auto",
    width: "auto",
  },
  slider: { margin: "0 0.5rem" },
  sliderWrapper: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    padding: "1rem",
    // @ts-ignore TODO: fix
    width: (props: Props) => props.size + props.border * 2,
  },
}));
