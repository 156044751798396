import { Avatar, Paper } from "@material-ui/core";
import { FC, memo } from "react";

import { Table } from "components";
import { appNavigation, getHistory, t } from "helpers";
import type { projectListQuery as projectListQueryType } from "providers/Api/__generated__/projectListQuery.graphql";

interface Props {
  onSearch?: (search?: string) => void;
  projects: projectListQueryType["response"]["projects"];
  search?: string;
}

export const ProjectsList: FC<Props> = memo(({ onSearch, projects, search }) => {
  return (
    <Paper className="m-5" elevation={10}>
      <Table
        // @ts-ignore TODO: fix
        columns={
          [
            {
              field: "logo",
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              render: (row: any) => <Avatar className="bg-dark" src={row.client?.logo?.fullPath} />,
            },
            { field: "code", title: t("Code") },
            { field: "name", title: t("Name") },
            { field: "description", title: t("Description") },
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ] as any
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        data={(projects?.edges?.map((i) => i.node) || []) as any}
        options={{
          search: true,
          searchText: search,
        }}
        title={t("Users")}
        totalCount={projects.totalCount || 0}
        // @ts-ignore TODO: improve
        onRowClick={(_e, row) => {
          row && getHistory().push(appNavigation.userDetail(row.id));
        }}
        onSearchChange={onSearch}
      />
    </Paper>
  );
});
