import { ComponentType, ReactElement } from "react";
import { useRouteMatch } from "react-router-dom";
import type { Variables, GraphQLTaggedNode } from "relay-runtime";
import * as Yup from "yup";

import { QueryRenderer } from "providers";

import { DetailPage } from "./DetailPage";
import { EntityContext } from "./EntityContext";

export interface SectionDefinition {
  component: ComponentType;
  key: string;
  label: string;
  title?: string;
}

interface Props {
  // cast form initials
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cast?: (e: any) => any;
  listRoute?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSave?: (data: any, props: any) => void;
  path: string;
  query?: GraphQLTaggedNode;
  schema: Yup.AnySchema;
  // TODO: use generic
  sections: SectionDefinition[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  title?: (e: any) => string;
}

export function DetailPageLayout<E extends Record<string, Record<string, unknown>>>({
  query,
  ...props
}: Props): ReactElement {
  const render = getRender<E>(props);

  const {
    params: { id },
  } = useRouteMatch<{ id: string }>();

  return query && id ? (
    <QueryRenderer<{ response: null | Record<string, unknown>; variables: Variables }>
      query={query}
      // @ts-ignore TODO: improve typings
      render={render}
      variables={{ id }}
    />
  ) : (
    render({})
  );
}

function getRender<E extends Record<string, null | Record<string, unknown>>>(
  props: Omit<Props, "detailQuery">
) {
  return (queryProps: { error?: Error; props?: E }) => {
    const { error, props: data } = queryProps;
    // @ts-ignore TODO: fix it
    const entity = data && props.path && data[props.path];

    return (
      <EntityContext.Provider value={entity}>
        {/* @ts-ignore TODO: fix once */}
        <DetailPage {...props} entity={entity || null} {...data} error={error} />
      </EntityContext.Provider>
    );
  };
}
