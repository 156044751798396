import { gql } from "@apollo/client";
import { FC } from "react";

import { t } from "helpers";
import { ListingPageLayout2 } from "layouts/ListingPageLayout";

const columns = [
  {
    Header: t("Name"),
    // @ts-ignore TODO: fix typing
    accessor: (row) => [row.profile.title, row.profile.fullName].filter(Boolean).join(". "),
  },
  { Header: t("Role"), accessor: "workData.position" },
  { Header: t("Card ID"), accessor: "workData.cardId" },
];

export const UsersListPage: FC = () => {
  return <ListingPageLayout2 columns={columns} query={users} />;
};

export const users = gql`
  query userPaginationQuery($first: Int, $after: String, $search: String) {
    list: users(first: $first, after: $after, filter: { search: $search }) {
      totalCount
      edges {
        node {
          id
          profile {
            fullName
            title
          }
          workData {
            position
            cardId
          }
        }
      }
    }
  }
`;
