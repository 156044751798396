import { createContext, useCallback, FC, useContext, useState } from "react";

const RefreshContext = createContext<number>(0);
const RefreshAction = createContext<() => void>(() => undefined);

export function useRefreshEffect(): void {
  useContext(RefreshContext);
}

export function useRefreshAction(): () => void {
  return useContext(RefreshAction);
}

export const RefreshProvider: FC = ({ children }) => {
  const [value, seter] = useState(0);

  const action = useCallback(() => seter((s) => s + 1), []);

  return (
    <RefreshAction.Provider value={action}>
      <RefreshContext.Provider value={value}>{children}</RefreshContext.Provider>
    </RefreshAction.Provider>
  );
};
