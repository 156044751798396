import Button from "@material-ui/core/Button";
import GraphiQL from "graphiql";
import { FC, useState } from "react";

import { AppLayout } from "layouts";
import { styled } from "providers";

import "graphiql/graphiql.min.css";
import "./overrides.css";

const graphQLFetcher = (variant: string) => (graphQLParams: Record<string, unknown>) => {
  let URL = (process.env.REACT_APP_TENANT ? "" : process.env.REACT_APP_GRAPHQL_ENDPOINT) || "";

  const headers: Record<string, string> = { "Content-Type": "application/json" };

  URL +=
    (variant === "public"
      ? process.env.REACT_APP_GRAPHQL_PUBLIC_ENDPOINT
      : process.env.REACT_APP_GRAPHQL_ENDPOINT) || "";

  return fetch(URL, {
    body: JSON.stringify(graphQLParams),
    headers,
    method: "post",
  })
    .then((response) => response.text())
    .then((response) => {
      let schema = response.replace(/</g, "lt");
      schema = schema.replace(/=/g, "eq");
      schema = schema.replace(/>/g, "gt");

      schema = JSON.parse(schema);

      return schema;
    });
};

const defaultQuery = `
  query testQuery {

  }
`;

export const DevPage: FC = () => {
  const [variant, setVariant] = useState("private");

  return (
    <AppLayout>
      <Wrapper>
        <GraphiQL
          defaultSecondaryEditorOpen
          defaultVariableEditorOpen
          docExplorerOpen
          defaultQuery={defaultQuery}
          fetcher={graphQLFetcher(variant)}
          toolbar={{
            additionalContent: (
              <Button
                color="primary"
                size="small"
                variant="outlined"
                onClick={() => setVariant((s) => (s === "public" ? "private" : "public"))}
              >
                {variant === "public" ? "Public" : "Private"}
              </Button>
            ),
          }}
        />
      </Wrapper>
    </AppLayout>
  );
};

const Wrapper = styled.div`
  font-weight: normal;
  width: 100%;
  height: 100%;
  display: flex;

  .topBar {
    height: auto !important;
  }
`;
