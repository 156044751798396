/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type ClientInput = {
    name: string;
    bussinessId?: string | null;
    taxId?: string | null;
    vat?: string | null;
    note?: string | null;
    discount?: number | null;
    type?: string | null;
    status?: string | null;
    address?: AddressInput | null;
    billingAddress?: AddressInput | null;
    deliveryAddress?: AddressInput | null;
    rating?: number | null;
    logo?: unknown | null;
    responsiblePersons?: Array<string> | null;
};
export type AddressInput = {
    addressUuid?: string | null;
    street?: string | null;
    city?: string | null;
    zip?: string | null;
    country?: string | null;
};
export type clientUpdateMutationVariables = {
    id: string;
    client: ClientInput;
};
export type clientUpdateMutationResponse = {
    readonly updateClient: {
        readonly id: string;
        readonly name: string;
        readonly type: string;
        readonly ico: string | null;
        readonly taxId: string | null;
        readonly vat: string | null;
        readonly note: string | null;
        readonly discount: number | null;
        readonly address: {
            readonly id: string;
            readonly street: string | null;
            readonly zip: string | null;
            readonly city: string | null;
            readonly country: string | null;
        } | null;
    };
};
export type clientUpdateMutation = {
    readonly response: clientUpdateMutationResponse;
    readonly variables: clientUpdateMutationVariables;
};



/*
mutation clientUpdateMutation(
  $id: String!
  $client: ClientInput!
) {
  updateClient(clientUuid: $id, client: $client) {
    id
    name
    type
    ico: bussinessId
    taxId
    vat
    note
    discount
    address {
      id
      street
      zip
      city
      country
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "client"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "client",
        "variableName": "client"
      },
      {
        "kind": "Variable",
        "name": "clientUuid",
        "variableName": "id"
      }
    ],
    "concreteType": "Client",
    "kind": "LinkedField",
    "name": "updateClient",
    "plural": false,
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "type",
        "storageKey": null
      },
      {
        "alias": "ico",
        "args": null,
        "kind": "ScalarField",
        "name": "bussinessId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "taxId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "vat",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "note",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "discount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Address",
        "kind": "LinkedField",
        "name": "address",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "street",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "zip",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "city",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "country",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "clientUpdateMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "clientUpdateMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "467cd56b67a618daa3207d31ae0d5a1c",
    "id": null,
    "metadata": {},
    "name": "clientUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation clientUpdateMutation(\n  $id: String!\n  $client: ClientInput!\n) {\n  updateClient(clientUuid: $id, client: $client) {\n    id\n    name\n    type\n    ico: bussinessId\n    taxId\n    vat\n    note\n    discount\n    address {\n      id\n      street\n      zip\n      city\n      country\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '9ea578e41e49948c03ad81769f0f9de9';
export default node;
