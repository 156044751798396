import { FC } from "react";

import { appNavigation } from "helpers";
import { ListingPageLayout } from "layouts/ListingPageLayout";
import type { client_pagination } from "providers/Api/__generated__/client_pagination.graphql";
import { clientPaginationQuery, clientPaginationFragment } from "providers/ApiProvider";

type Client = client_pagination["list"]["edges"][0]["node"];

const columns = [
  {
    Header: "Name",
    accessor: (r: Client) => <a href={appNavigation.clientDetail(r.id)}>{r.name}</a>,
  },

  {
    Header: "Ico",
    accessor: "bussinessId",
  },
];

export const ClientListPage: FC = () => {
  return (
    <ListingPageLayout
      columns={columns}
      fragment={clientPaginationFragment}
      query={clientPaginationQuery}
    />
  );
};
