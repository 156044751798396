import { ReactElement } from "react";

import { UIField } from "components/Form/UIFIeld.styled";

import type { InputInterface } from "..";

export type TextInputProps = InputInterface<undefined | null | string> & {
  type?: HTMLInputElement["type"];
};

export function TextInput({
  disabled,
  error,
  helperText,
  id,
  label,
  name,
  onBlur,
  onChange,
  type = "text",
  value,
}: TextInputProps): ReactElement {
  return (
    <UIField error={error} helperText={helperText} id={id} label={label}>
      <input
        disabled={disabled}
        id={id}
        name={name}
        type={type}
        value={typeof value !== "string" && typeof value !== "number" ? "" : value}
        onBlur={() => onBlur && onBlur()}
        onChange={(e) => {
          onChange && onChange(e.currentTarget.value || undefined);
        }}
      />
    </UIField>
  );
}
