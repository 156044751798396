/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type publicForgotPasswordMutationVariables = {
    email: string;
};
export type publicForgotPasswordMutationResponse = {
    readonly forgotPassword: boolean;
};
export type publicForgotPasswordMutation = {
    readonly response: publicForgotPasswordMutationResponse;
    readonly variables: publicForgotPasswordMutationVariables;
};



/*
mutation publicForgotPasswordMutation(
  $email: String!
) {
  forgotPassword(email: $email)
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "email"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "email",
        "variableName": "email"
      }
    ],
    "kind": "ScalarField",
    "name": "forgotPassword",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "publicForgotPasswordMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "publicForgotPasswordMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "e661a57d893e70fac2e84746dcd54941",
    "id": null,
    "metadata": {},
    "name": "publicForgotPasswordMutation",
    "operationKind": "mutation",
    "text": "mutation publicForgotPasswordMutation(\n  $email: String!\n) {\n  forgotPassword(email: $email)\n}\n"
  }
};
})();
(node as any).hash = '469960d2960e8d68a985cc54515e12e3';
export default node;
