import { FC } from "react";

import { styled } from "providers/theme";

import { ListItem } from "./ListItem.styled";

export interface ListProps {
  className?: string;
  dense?: boolean;
}

const Component: FC<ListProps> = ({ children, className }) => {
  return <ul className={className}>{children}</ul>;
};

export const List = styled(Component)<ListProps>`
  list-style: none;
  padding: 0;
  margin: ${({ dense }) => (dense ? "0.25rem 0" : "0.5rem 0")};

  & > ${ListItem} {
    padding: ${({ dense }) => (dense ? "0.15rem 1rem" : "0.5rem 1rem")};
  }
`;
