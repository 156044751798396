import { forwardRef } from "react";

import { styled } from "providers/theme";

import { Icon } from "../../common/Icon";
import type { IconProps } from "../../common/Icon";

import { Button } from "./Button";
import type { ButtonProps } from "./Button";

export interface IconButtonProps extends Omit<ButtonProps, "endIcon" | "startIcon"> {
  name: IconProps["name"];
}

const Component = forwardRef<HTMLButtonElement, IconButtonProps>(({ name, ...props }, ref) => (
  <Button ref={ref} {...props}>
    <Icon name={name} size="1em" />
  </Button>
));

export const IconButton = styled(Component)<IconButtonProps>`
  border-radius: 100%;
  padding: 0 !important;
  width: ${({ size }) =>
    size === "large" ? "3rem" : size === "small" ? "1.5rem" : "2rem"} !important;
  height: ${({ size }) =>
    size === "large" ? "3rem" : size === "small" ? "1.5rem" : "2rem"}!important;
  overflow: hidden;
  font-size: ${({ size }) => (size === "large" ? "2rem" : size === "small" ? "0.75rem" : "1.2rem")};
`;
