import {
  Middleware,
  urlMiddleware,
  RelayNetworkLayer,
  RelayNetworkLayerRequest as RelayRequest,
} from "react-relay-network-modern";
import { Environment } from "relay-runtime";
import { Store, RecordSource } from "relay-runtime";

import uploadMiddleware from "./uploadMiddleware";

export const host =
  (process.env.REACT_APP_TENANT ? "" : process.env.REACT_APP_GRAPHQL_ENDPOINT) || "";
export const appEndpoint = process.env.REACT_APP_GRAPHQL_ENDPOINT || "";
export const publicEndpoint = process.env.REACT_APP_GRAPHQL_PUBLIC_ENDPOINT || ""; // call unauthorized requests

const middlewares: Middleware[] = [];

/**
 * Url middleware - basic headers and endpoint
 */
middlewares.push(
  urlMiddleware({
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
    },
    method: "POST",
    url: ({ operation }: RelayRequest) => {
      const isPublic = operation.name.startsWith("public");

      const url = `${host + (isPublic ? publicEndpoint : appEndpoint)}`;
      return url;
    },
  })
);

/**
 * Operation name to url
 */
if (process.env.NODE_ENV !== "production") {
  middlewares.push(function operationNameMiddleware(next) {
    return (req) => {
      // TODO: handle batch
      if ("operation" in req) {
        const url = req.fetchOpts.url || "";
        const operation = req.operation.name || null;

        req.fetchOpts.url =
          operation && typeof operation === "string" ? `${url}?op=${operation}` : url;
      }
      return next(req);
    };
  });
}

// Uploading middleware
middlewares.push(uploadMiddleware);

const options = {};

const network = new RelayNetworkLayer(middlewares, options);

const environment = new Environment({
  network,
  store: new Store(new RecordSource()),
});

export function getGQLEnvironment(): Environment {
  return environment;
}
