import { MouseEvent } from "react";

/**
 * Create material ripple effect
 * @param event
 * @returns
 */
export function createRipple(event: MouseEvent): void {
  const button = event.currentTarget;

  if (!button) {
    return;
  }

  const circle = document.createElement("span");
  const diameter = Math.max(button.clientWidth, button.clientHeight);
  const radius = diameter / 2;

  const rect = button.getBoundingClientRect();

  circle.style.width = circle.style.height = `${diameter}px`;
  circle.style.left = `${event.clientX - rect.x - radius}px`;
  circle.style.top = `${event.clientY - rect.y - radius}px`;
  circle.classList.add("ripple");

  const ripple = button.getElementsByClassName("ripple")[0];

  if (ripple) {
    ripple.remove();
  }

  button.appendChild(circle);
}
