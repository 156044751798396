import { IconButton, Paper } from "@material-ui/core";
import { useMemo, FC } from "react";
import { Helmet } from "react-helmet";
import { useMutation } from "react-relay/hooks";

import { Form, Icon, TabContent, Tabs } from "components";
import { t } from "helpers";
import { clientUpdateMutation } from "providers";
import type { clientDetailQuery as clientDetailQueryType } from "providers/Api/__generated__/clientDetailQuery.graphql";

import { AddressesSection } from "./sections/AddressesSection";
import { BasicInfoSection } from "./sections/BasicInfoSection";
import { useSchema, ClientData } from "./useSchema";

export const ClientDetail: FC<{
  client: NonNullable<clientDetailQueryType["response"]["client"]>;
}> = ({ client }) => {
  const tabs = [
    { formItems: ["info"], key: "basic-info", label: t("Basic Info") },
    { formItems: ["addresses"], key: "addresses", label: t("Addresses") },
    { formItems: ["contacts"], key: "contacts", label: t("Contacts") },
    { formItems: ["payments"], key: "payments", label: t("Payments") },
  ];

  const [dispatch] = useMutation(clientUpdateMutation);
  const schema = useSchema();
  const inits = useMemo(() => schema.cast({ client, info: client }, { stripUnknown: true }), [
    client,
    schema,
  ]);

  const handleSubmit = (data: ClientData) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    // const input = JSON.parse(JSON.stringify({ clientUuid: client.id, ...data })) as any;

    const input = {
      bussinessId: data.info.ico,
      clientUuid: client.id,
      discount: data.info.discount,
      name: data.info.name,
      note: data.info.note,
      taxId: data.info.taxId,
      type: data.info.type,
      vat: data.info.vat,
    };

    dispatch({ variables: { client: input } });
  };

  return (
    <>
      <Helmet>
        <title>{t("Evoneto | Client {client}", { client: client.name })}</title>
      </Helmet>
      <div className="p-4">
        <div className="d-flex justify-content-between mb-3">
          <h1>{client.name}</h1>
          <div>
            <IconButton color="primary" form="client" title={t("Save")} type="submit">
              <Icon name="save" />
            </IconButton>
          </div>
        </div>

        <Tabs className="mb-4" name="card" options={tabs} preselect="basic-info" />

        <Paper className="p-5" elevation={5}>
          <Form<ClientData>
            id="client"
            initialValues={inits}
            schema={schema}
            onSubmit={handleSubmit}
          >
            <TabContent name="card" tab="basic-info">
              <BasicInfoSection />
            </TabContent>

            <TabContent name="card" tab="addresses">
              <AddressesSection />
            </TabContent>
          </Form>
        </Paper>
      </div>
    </>
  );
};
