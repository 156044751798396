import { FC } from "react";

import { useQueryParams } from "helpers";

export const TabContent: FC<{ name?: string; tab: string }> = ({ children, name, tab }) => {
  const params = useQueryParams();
  const activeTab = (name && params[name]) || "tab";

  if (tab !== activeTab) {
    return null;
  }

  return <>{children}</>;
};
