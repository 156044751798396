import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { FC, Suspense, useState } from "react";
import { GraphQLTaggedNode } from "react-relay";
import { usePaginationFragment, useLazyLoadQuery } from "react-relay/hooks";

interface Props {
  fragment: GraphQLTaggedNode;
  query: GraphQLTaggedNode;
}

type Opt = { name: string; value: string };

export const ListPicker: FC<Props> = ({ fragment, query }) => {
  const [open, setOpen] = useState(false);
  const [options] = useState<Opt[]>([]);
  const loading = open && options.length === 0;
  const [value, setValue] = useState("");

  const items: unknown[] = [];

  return (
    <>
      <Suspense fallback="Loading">
        <Q f={fragment} q={query} search={value}>
          gg
        </Q>
      </Suspense>
      <Autocomplete
        getOptionLabel={(option: Opt) => option.name}
        //  getOptionSelected={(option: Opt, value: Opt) => option.name === value.name}
        id="asynchronous-demo"
        loading={loading}
        open={open}
        // TODO: make it generic
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        options={(items || []).map((u: any) => ({ name: u.profile.fullName, value: u.id }))}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            label="Asynchronous"
            value={value}
            variant="outlined"
            onChange={(e) => setValue(e.currentTarget.value || "")}
          />
        )}
        style={{ width: 300 }}
        onClose={() => {
          setOpen(false);
        }}
        onOpen={() => {
          setOpen(true);
        }}
      />
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Q: FC<{ f: any; q: any; search: string }> = ({ children, f, q, search }) => {
  const qRef = useLazyLoadQuery(q, { search });

  return (
    <F f={f} qRef={qRef}>
      {children}
    </F>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const F: FC<{ f: any; qRef: any }> = ({ children, f, qRef }) => {
  // eslint-disable-next-line no-empty-pattern
  const {
    // data,
    // loadNext,
    // loadPrevious,
    // hasNext,
    // hasPrevious,
    // isLoadingNext,
    // isLoadingPrevious,
    // refetch, // For re-fetching connection
  } = usePaginationFragment(f, qRef);

  return <>{children}</>;
};
