import { FC, ReactNode, useEffect, useRef } from "react";
import type { HeaderGroup } from "react-table";

import { IconButton } from "components/actions/Button";
import { cn, t, useChangeEffect } from "helpers";
import { styled } from "providers/theme";

interface Column extends HeaderGroup<never> {
  canResize?: boolean;
  canSort?: boolean;
  getResizerProps?: () => Record<string, never>;
  getSortByToggleProps?: () => Record<string, never>;
  isResizing?: boolean;
  isSorted?: boolean;
  isSortedDesc?: boolean;
  render: (type: string) => ReactNode;
}

export interface ThProps {
  className?: string;
  column: Column;
  onResize?: () => void;
}

const Component: FC<ThProps> = ({ className, column, onResize }) => {
  // @ts-ignore TODO: extend
  const props = column.getHeaderProps();
  delete (props.style as Record<string, unknown>).display;
  // @ts-ignore TODO: extend
  const title = column.Header?.toString();

  const isResizing = Boolean(column.isResizing);
  const resizeCb = useRef(onResize);

  useEffect(() => {
    resizeCb.current = onResize;
  }, [onResize]);

  useChangeEffect(() => {
    if (!isResizing && resizeCb.current) {
      resizeCb.current();
    }
  }, [isResizing]);

  return (
    <div
      // @ts-ignore TODO:  improve
      {...props}
      className={cn(className, { isResizing })}
    >
      <div className="title">{column.render("Header")}</div>
      {column.canSort && column.getSortByToggleProps && (
        <IconButton
          className={cn("sort", { "can-sort": !column.isSorted })}
          name={column.isSortedDesc ? "sort-up" : "sort-down"}
          {...column.getSortByToggleProps()}
          title={t("Sort byt {title}", { title })}
        />
      )}

      {column.canResize && column.getResizerProps && (
        <div
          {...column.getResizerProps()}
          className={`resizeHandle ${column.isResizing ? "isResizing" : ""}`}
        />
      )}
    </div>
  );
};

export const Th: FC<ThProps> = styled(Component)<ThProps>`
  position: relative;
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.25rem 0.5rem;
  font-weight: bold;
  height: 3rem;

  .title {
    max-width: 100%;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  ${IconButton} {
    color: ${({ theme }) => theme.textColor};

    &.can-sort {
      color: transparent;
    }

    background: transparent !important;
    border: none;
  }

  &:hover ${IconButton} {
    &.can-sort {
      color: ${({ theme }) => theme.palette.gray.main};
    }
  }

  .resizeHandle {
    opacity: 0;
    cursor: ev-resize;
    width: 1rem;
    background: transparent;
    height: 100%;
    display: inline-block;
    position: absolute;
    right: -0.5rem;
    z-index: 2;

    &:before {
      position: absolute;
      right: 6px;
      top: 16px;
      width: 1px;
      content: "";
      border: 1px solid ${({ theme }) => theme.palette.gray.main};
      border-top-width: 0;
      border-bottom-width: 0;
      height: 1rem;
      display: block;
      box-sizing: content-box;
    }
  }

  &.isResizing,
  &:hover {
    .resizeHandle {
      opacity: 1;
    }
  }

  &.expand {
    flex: 1;
    width: auto !important;

    .resizeHandle {
      display: none;
    }
  }
`;
