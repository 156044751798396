import { ReactElement, useCallback } from "react";
import { TableInstance } from "react-table";
import { FixedSizeList } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";

import { AutoSizerProvider, useAutoSize } from "./AutoSizerProvider";
import { useRenderRow } from "./useRenderRow";

interface RenderAutoSizeVirtualTableProps<T extends Record<string, unknown>> {
  count: number;
  itemSize?: number;
  loadMore?: (next: number) => Promise<void>;
  table: TableInstance<T>;
  total: number;
}

export function RenderVirtualTable<T extends Record<string, unknown>>({
  count,
  itemSize = 48,
  loadMore,
  table,
  total,
}: RenderAutoSizeVirtualTableProps<T>): ReactElement {
  const isItemLoaded = useCallback((index: number) => index < count, [count]);
  const { height, width } = useAutoSize();

  const handleLoadMore = useCallback(
    (_start, end: number) => {
      const more = Math.max(end - count + 1, 0);

      return more && loadMore ? loadMore(more) : Promise.resolve();
    },
    [count, loadMore]
  );

  const { rows } = table;

  const RenderRow = useRenderRow(table);

  return (
    <AutoSizerProvider>
      <InfiniteLoader isItemLoaded={isItemLoaded} itemCount={count} loadMoreItems={handleLoadMore}>
        {({ onItemsRendered, ref }) => (
          <FixedSizeList
            ref={ref}
            // @ts-ignore TODO:...
            children={RenderRow}
            height={height}
            itemCount={Math.max(total, rows.length)}
            itemSize={itemSize}
            width={width}
            onItemsRendered={onItemsRendered}
          />
        )}
      </InfiniteLoader>
    </AutoSizerProvider>
  );
}
