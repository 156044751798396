import { styled } from "providers/theme";

import { Tr } from "./Tr.styled";

export const Table = styled.div`
  flex-direction: column;
  display: inline-flex;
  position: relative;
  border-spacing: 0;
  z-index: 0;
  flex: 1;
  min-height: 500px;

  .th,
  .td {
    margin: 0;
    padding: 0.5rem;
    border-bottom: 1px solid black;
    border-right: 1px solid black;

    :last-child {
      border-right: 1px solid black;
    }
  }

  th {
    font-weight: bold;
  }

  .tr {
    :last-child {
      .td {
        border-bottom: 0;
      }
    }
  }
`;

export const TBody = styled.div`
  flex-direction: column;
  display: flex;
  z-index: 0;
  flex: 1;
  overflow: auto;
`;

export const Thead = styled.div`
  position: relative;
  z-index: 1;

  ${Tr} {
    box-shadow: ${({ theme }) => theme.shadow.thead};
  }
`;
