import { FC, ReactNode } from "react";

import { useSession } from "./AuthProvider";

interface Props {
  fallback?: ReactNode;
}

export const Can: FC<Props> = ({ children, fallback }) => {
  const { user } = useSession() || { user: undefined };

  if (!user) {
    return <>{fallback || undefined}</>;
  }

  return <>{children}</>;
};
