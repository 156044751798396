import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import { FC, useMemo } from "react";
import { Helmet } from "react-helmet";
import { useMutation } from "react-relay/hooks";

import { Form, Icon, Tabs, TabContent } from "components";
import { t } from "helpers";
import { userMutation } from "providers/Api";
import { useUser } from "providers/AuthProvider";

import { useSchema, ProfileFormData } from "./schema";
import { Address } from "./sections/Address";
import { PersonalDetails } from "./sections/PersonalDetails";

export const ProfilePage: FC = () => {
  const [dispatch] = useMutation(userMutation);
  const schema = useSchema();
  const user = useUser();

  const handleSubmit = (data: ProfileFormData) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const input = JSON.parse(JSON.stringify({ userUuid: user.id, ...data })) as any;
    // @ts-ignore TODO: ad middle-name support
    delete input.profile.age;

    input.profile.birthday = input.profile.birthday
      ? new Date(input.profile.birthday).toISOString().substr(0, 10)
      : null;

    input.profile.nameday = input.profile.nameday
      ? new Date(input.profile.nameday).toISOString().substr(0, 10)
      : null;

    dispatch({ variables: { user: input } });
  };

  const inits = useMemo(() => schema.cast(user, { stripUnknown: true }), [schema, user]);

  const tabs = useMemo(
    () => [
      { formItems: ["personal"], key: "personal-info", label: t("Personal Info") },
      { key: "address", label: t("Address") },
      // { key: "contacts", label: t("Contact details") },
      // { key: "employee-profile", label: t("Employment") },
      // { key: "projects", label: t("Projects") },
      // { key: "worklog", label: t("Worklog") },
      // { key: "files", label: t("Files") },
      { key: "account", label: t("Account") },
    ],
    []
  );

  return (
    <>
      <Helmet>
        <title>{t("Evoneto | My Profile")}</title>
      </Helmet>

      <div className="p-4">
        <div className="d-flex justify-content-between mb-3">
          <h1>{user.profile.fullName || user.email}</h1>
          <div>
            <IconButton color="primary" form="user" title={t("Save")} type="submit">
              <Icon name="save" />
            </IconButton>
          </div>
        </div>

        <Tabs className="mb-4" name="card" options={tabs} preselect="profile" />

        <Paper className="py-5 px-2" elevation={5}>
          <Form id="user" initialValues={inits} schema={schema} onSubmit={handleSubmit}>
            <TabContent name="card" tab="profile">
              <PersonalDetails />
            </TabContent>
            <TabContent name="card" tab="address">
              <Address />
            </TabContent>
          </Form>
        </Paper>
      </div>
    </>
  );
};
