/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type UserInput = {
    userUuid?: string | null;
    roleUuid?: string | null;
    profile?: ProfileInput | null;
    workData?: WorkDataInput | null;
    address?: AddressInput | null;
};
export type ProfileInput = {
    userProfileUuid?: string | null;
    firstName?: string | null;
    middleName?: string | null;
    lastName?: string | null;
    title?: string | null;
    phone?: string | null;
    about?: string | null;
    birthday?: unknown | null;
    nameday?: unknown | null;
    gender?: string | null;
    profilePhoto?: unknown | null;
};
export type WorkDataInput = {
    userWorkDataUuid?: string | null;
    personalId?: string | null;
    cardId?: string | null;
    pin?: string | null;
    gdpr?: boolean | null;
    position?: string | null;
    supervisor?: string | null;
    department?: string | null;
    employedFrom?: unknown | null;
    employmentType?: string | null;
    employmentTrialPeriod?: unknown | null;
    lastEmploymentDataDateChange?: unknown | null;
    grossWage?: number | null;
    superGrossWage?: number | null;
};
export type AddressInput = {
    addressUuid?: string | null;
    street?: string | null;
    city?: string | null;
    zip?: string | null;
    country?: string | null;
};
export type userUpdateMutationVariables = {
    user: UserInput;
};
export type userUpdateMutationResponse = {
    readonly updateUser: {
        readonly __typename: string;
        readonly id: string;
        readonly profile: {
            readonly fullName: string;
            readonly firstName: string;
            readonly middleName: string;
            readonly lastName: string;
            readonly nameday: string | null;
            readonly birthday: unknown | null;
            readonly gender: string;
            readonly age: number;
            readonly profilePhoto: {
                readonly fullPath: string;
            } | null;
        };
        readonly workData: {
            readonly position: string;
        };
        readonly address: {
            readonly street: string | null;
            readonly city: string | null;
            readonly zip: string | null;
            readonly country: string | null;
        };
    };
};
export type userUpdateMutation = {
    readonly response: userUpdateMutationResponse;
    readonly variables: userUpdateMutationVariables;
};



/*
mutation userUpdateMutation(
  $user: UserInput!
) {
  updateUser(user: $user) {
    __typename
    id
    profile {
      fullName
      firstName
      middleName
      lastName
      nameday
      birthday
      gender
      age
      profilePhoto {
        fullPath
      }
    }
    workData {
      position
    }
    address {
      street
      city
      zip
      country
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "user"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "user",
        "variableName": "user"
      }
    ],
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "updateUser",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserProfile",
        "kind": "LinkedField",
        "name": "profile",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fullName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "middleName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nameday",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "birthday",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "gender",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "age",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "File",
            "kind": "LinkedField",
            "name": "profilePhoto",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "fullPath",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserWorkData",
        "kind": "LinkedField",
        "name": "workData",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "position",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Address",
        "kind": "LinkedField",
        "name": "address",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "street",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "city",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "zip",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "country",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "userUpdateMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "userUpdateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "0bb7116f08544fce0ba589d2bbce2431",
    "id": null,
    "metadata": {},
    "name": "userUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation userUpdateMutation(\n  $user: UserInput!\n) {\n  updateUser(user: $user) {\n    __typename\n    id\n    profile {\n      fullName\n      firstName\n      middleName\n      lastName\n      nameday\n      birthday\n      gender\n      age\n      profilePhoto {\n        fullPath\n      }\n    }\n    workData {\n      position\n    }\n    address {\n      street\n      city\n      zip\n      country\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '0d1f7bc1b747d1fb140dddd6c935881e';
export default node;
