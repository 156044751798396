import { FormHelperText } from "@material-ui/core";
import { FC, useEffect } from "react";
import { useMutation } from "react-relay/hooks";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";

import { appNavigation, t } from "helpers";
import { AuthLayout } from "layouts";
import { userLogoutMutation } from "providers/Api";
import type { userLogoutMutation as userLogoutMutationType } from "providers/Api/__generated__/userLogoutMutation.graphql";
import { useSetSession, useSession } from "providers/AuthProvider";

export const LogoutPage: FC = () => {
  const session = useSession();
  const setSession = useSetSession();

  const [dispatch] = useMutation<userLogoutMutationType>(userLogoutMutation);

  useEffect(() => {
    dispatch({
      onCompleted: () => {
        setSession(undefined);
      },
      onError: (error) => {
        setSession(undefined);
        error && toast.error(t("Oops, {msg}", { msg: error.message || error }));
      },
      variables: {},
    });
  }, [dispatch, setSession]);

  if (!session || !session.user) {
    return <Redirect to={appNavigation.home()} />;
  }

  return (
    <AuthLayout>
      <h2 className="mb-5">{t("Log out")}</h2>
      <FormHelperText className="mb-3">{t("We are logging you out please wait...")}</FormHelperText>
    </AuthLayout>
  );
};
