import { IconButton } from "components/actions/Button";
import { styled } from "providers/theme";

export const Overlay = styled.div.attrs<{ isOpen?: boolean }>((p) => ({
  className: p.isOpen ? "visible" : "",
}))<{ isOpen?: boolean }>`
  background: rgba(0, 0, 0, 0.75);
  justify-content: center;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  position: fixed;
  max-width: 100%;
  display: none;
  z-index: 1000;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;

  &.visible {
    display: flex;
  }
`;

export const Page = styled.div.attrs<{ fullPage?: boolean }>((p) => ({
  className: p.fullPage ? "fullPage" : "",
}))<{ fullPage?: boolean }>`
  background: ${({ theme }) => theme.palette.light.main};
  border-radius: ${({ theme }) => theme.radius.normal};
  flex-direction: column;
  position: relative;
  min-height: 10rem;
  min-width: 30rem;
  display: flex;
  margin-top: 2rem;
  margin-bottom: 2rem;
  overflow: hidden;

  &.fullPage {
    width: 100%;
    height: 100%;
    margin-top: 0;
    margin-bottom: 0;
  }

  & > ${IconButton} {
    color: ${({ theme }) => theme.palette.gray.main};
    border-color: transparent;
  }

  & > ${/* sc-selector */ IconButton}.absolute {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;

    :hover {
      color: ${({ theme }) => theme.palette.gray.dark};
    }
  }
`;

export const Actions = styled.div`
  margin-left: auto;
  padding: 1rem;

  & > *:not(:first-child) {
    margin-left: 1rem;
  }
`;

export const Content = styled.div`
  max-width: 100%;
  overflow: auto;
  padding: 1rem;
  flex: 1;
`;

export const Header = styled.div`
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding: 1rem;
  display: flex;

  h4 {
    font-size: 1.125rem;
    font-weight: bold;
    margin: 0;
  }
`;
