import { Button } from "@material-ui/core";
import { FC } from "react";
import { Link, Redirect, useLocation } from "react-router-dom";
import styled from "styled-components/macro";

import banner from "assets/images/auth_banner.png";
import logo from "assets/images/logo.png";
import { appNavigation } from "helpers";
import { t } from "helpers/translate";
import { getVersion } from "helpers/version";
import { useSession } from "providers/AuthProvider";

import { style } from "./Auth.styled";

export interface AuthLayoutProps {
  className?: string;
  left?: boolean;
}

const Component: FC<AuthLayoutProps> = ({ children, className }) => {
  const { pathname } = useLocation();
  const ses = useSession();

  if (ses && ses.user && pathname !== appNavigation.logout()) {
    return <Redirect to={appNavigation.home()} />;
  }

  return (
    <div className={className}>
      <main>
        <Link role="home" to={appNavigation.home()}>
          <img
            alt={t("Evoneto brand")}
            aria-label="Brand"
            src={logo}
            title={t("Evoneto")}
            width={32}
          />
        </Link>

        <div>{children}</div>

        <span role="code">{getVersion()}</span>
      </main>

      <aside>
        <h1 aria-label="Highlight">{t("Office reinvented")}</h1>
        <p arial-label="Short description">
          {t("Evoneto changes the way you manages your business.")}
        </p>
        <img alt="banner" role="banner" src={banner} />
        <Button color="primary" variant="outlined">
          {t("Find out more")}
        </Button>
      </aside>
    </div>
  );
};

export const AuthLayout = styled(Component)<AuthLayoutProps>`
  ${style}
`;
