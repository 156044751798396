import { App } from "App";
import ReactDOM from "react-dom";

import reportWebVitals from "./reportWebVitals";

const rootElement = document.getElementById("root");

ReactDOM.render(<App />, rootElement);

function check_ga() {
  if (typeof window.ga === "function") {
    // eslint-disable-next-line no-console
    console.log("Loaded :" + window.ga);
  } else {
    // eslint-disable-next-line no-console
    setTimeout(check_ga, 500);
  }
}
check_ga();

if (process.env.NODE_ENV === "production") {
  reportWebVitals();
}
