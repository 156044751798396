import op from "object-path";
import * as Yup from "yup";

import { toast } from "helpers/toast";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Data = Record<string, any>;
export interface FormState<D extends Data> {
  changed: Record<string, boolean>;
  errors: Record<string, string>;
  isValid: boolean;
  isValidating: boolean;
  schema?: Yup.SchemaOf<D>;
  submitted: number;
  touched: Record<string, boolean>;
  values: D;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type FormAction<D extends Data = any> =
  | { name: string; type: "onChange"; value: unknown }
  | { name: string; type: "onBlur" }
  | { cb: (data: D) => void; type: "onSubmit" };

export function formReducer<D extends Data>(state: FormState<D>, action: FormAction): FormState<D> {
  switch (action.type) {
    case "onChange":
      const values = { ...state.values };
      if (op.get(values, action.name) === action.value) {
        return state;
      }

      op.set(values, action.name, action.value);

      return {
        ...state,
        changed: { ...state.changed, [action.name]: true },
        ...validate<D>(state.schema, values),
      };
    case "onBlur":
      return state.touched[action.name]
        ? state
        : {
            ...state,
            touched: { ...state.touched, [action.name]: true },
          };

    case "onSubmit":
      const s = {
        ...state,
        submitted: state.submitted + 1,
        ...validate<D>(state.schema, state.values),
      };

      if (s.isValid) {
        // fork out
        setTimeout(() => action.cb(s.values), 1);
      } else {
        Object.entries(s.errors).forEach(([key, error]) => {
          toast.error(`${key}: ${error}`);
        });
      }

      return s;

    default:
      return state;
  }
}

function validate<D>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  schema: undefined | Yup.ObjectSchema<any>,
  data: D
): { errors: Record<string, string>; isValid: boolean; values: D } {
  const errors: Record<string, string> = {};
  let values: D = data;

  if (!schema) {
    return { errors: {}, isValid: true, values: data };
  }

  try {
    values = schema.validateSync(data, {
      abortEarly: false,
    }) as D;
  } catch (err) {
    if (err.inner) {
      err.inner.forEach((errObj: Yup.ValidationError) => {
        if (errObj.path) {
          errors[errObj.path] = errObj.message;
        }
      });
    }
  }

  return {
    errors,
    isValid: !errors || Object.keys(errors).length === 0,
    values,
  };
}
