import man from "assets/images/man.png";
import other from "assets/images/other.png";
import woman from "assets/images/woman.png";

const genderIcons = {
  f: woman,
  m: man,
  o: other,
};

export function userAvatar(
  user:
    | undefined
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    | any
    | { gender?: string | null; profilePhoto?: null | { fullPath?: string | null } }
): string {
  const gender: "m" | "f" | "o" =
    user && user.gender === "m" ? "m" : user && user.gender === "f" ? "f" : "o";

  return (
    (user && user.profile.profilePhoto ? user.profile.profilePhoto?.fullPath : undefined) ||
    genderIcons[gender]
  );
}
